import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import {
    toggle_panel_detail_is_open, toggle_panel_detail_content_is_open
} from '../../actions'
import JSONDataContext from "../../context"

import DetailEmpty from "./DetailEmpty"
import DetailStation from "./DetailStation"
import DetailLine from "./DetailLine"
import {build_router_infos} from '../../utils/Router'


class DetailPanel extends React.Component {
	
	constructor(props) {
		super(props);
		this.main_panel_ref = React.createRef(); // width for desktop
		this.main_panel_header_ref = React.createRef(); // height for mobile: @TODO: still correct?? needed for map centering and bound calculations!
        this.scroll_container_current_ref = null // assigned trough child > parent method call from perfectScrollbar
		this.on_move_transition_end.bind(this);

		/* not used yet -> panel fade in/out on content change, see componentDidUpdate */
		this.state = {
			panel_content_show: false, // fade out or fade in, false = hidden
            detail_fade_class: ''
		}
	}
	
	componentDidUpdate = (prev_props) => {
		const {
			panel_detail_open,
			panel_detail_is_open,
			pathname
		} = this.props

        // console.log('DetailPanel:componentDidUpdate')

        if(pathname !== prev_props.pathname) {
        	this.reset_scroll()
		}

		/*
			todo, handle pathname change logic. We need a inner div after
			main tag, to fade the content in/out and listen on transition
			end to set panel_content_show correct

			-> on Close the Panel is empty now -> not sliding out
		*/
		// initial fade in
		if(panel_detail_is_open && !prev_props.panel_detail_is_open) {
			// console.log('DetailPanel: todo fade in content')
		}
		// fade out if the panel is going to hide -> user @ home
		if(!panel_detail_open && prev_props.panel_detail_open) {
			// console.log('DetailPanel: todo, fade out content')
		}
		// fade out/in if the pathname changes, but panel stays open
		if(pathname !== prev_props.pathname &&
		   panel_detail_is_open && prev_props.panel_detail_is_open &&
		   panel_detail_open &&  prev_props.panel_detail_open) { // pathname changed while panel is/was open
			// console.log('DetailPanel: todo, handle fade in/out on pathname change')

            // onTransitionEnd doeas not fire when fadeOut is 0s, so we need setTimeout to re-add the class
            this.setState({detail_fade_class: 'detail-fade-zero'})
            setTimeout(this.fade_in_content, 300)
		}
	}

	fade_in_content = () => {
	    this.setState({detail_fade_class: ''})
    }

	on_move_transition_end = (event) => {
		if(event.propertyName !== 'opacity') { // only if not opacity only
			const {
				panel_detail_open,
				client_type
			} = this.props
			let new_panel_detail_is_open = panel_detail_open ? true : false;
			let new_panel_detail_space = 0
			if(client_type !== 'mobile') {
				new_panel_detail_space = new_panel_detail_is_open ? this.main_panel_ref.current.offsetWidth: 0
			} else {
				new_panel_detail_space = new_panel_detail_is_open ? this.main_panel_header_ref.current.offsetHeight: 0
				// console.log('mobile, new_panel_detail_space: '+new_panel_detail_space)
			}
			this.props.toggle_panel_detail_is_open(new_panel_detail_is_open, new_panel_detail_space)
		}
	}

	reset_scroll = () => {
	    if(this.scroll_container_current_ref !== null){
			// console.log('reset scroll, scrollTop: '+this.scroll_container_current_ref.scrollTop)
			this.scroll_container_current_ref.scrollTop = 0;
		}
    }

	// child perfect scrollbar components updates the ref
	set_scroll_ref_current = (scroll_container_current_ref) => {
		this.scroll_container_current_ref = scroll_container_current_ref
	}
	
	render = () => {
		const { json_loaded, pathname } = this.props
		const router_infos = build_router_infos(pathname)
        const panel_type_class = 'detail-'+router_infos.type // 'detail-station', 'detail-line'
        let content = (
            <DetailEmpty />
        )

        if (router_infos.type === 'station') {
            content = (
                <DetailStation main_panel_header_ref={this.main_panel_header_ref} set_scroll_ref_current={this.set_scroll_ref_current} />
            )
        }else if (router_infos.type === 'line') {
            content = (
                <DetailLine main_panel_header_ref={this.main_panel_header_ref} set_scroll_ref_current={this.set_scroll_ref_current} />
            )
        }

		if(json_loaded) {
			return (
				<main className={`detail ${panel_type_class} ${this.state.detail_fade_class}`} onTransitionEnd={this.on_move_transition_end} ref={this.main_panel_ref}>
                    {content}
				</main>
			)
		} else {
			return (
				<main className="detail detail-station" onTransitionEnd={this.on_move_transition_end} ref={this.main_panel_ref}>
				</main>
			)
		}
	}
}

const map_state_to_props = state => {
	return {
		panel_detail_open: state.general.panels.panel_detail_open,
		panel_detail_is_open: state.general.panels.panel_detail_is_open,
		json_loaded: state.general.data.json_loaded,
		client_type: state.general.ui.client_type,
		pathname: state.router.location.pathname
	}
}

const map_dispatch_to_props = dispatch => {
	return {
		toggle_panel_detail_is_open: (panel_detail_is_open, panel_detail_space) => {
			dispatch(toggle_panel_detail_is_open(panel_detail_is_open, panel_detail_space))
		},
		toggle_panel_detail_content_is_open: (panel_detail_content_is_open) => {
			dispatch(toggle_panel_detail_content_is_open(panel_detail_content_is_open))
		}
	}
}

DetailPanel.contextType = JSONDataContext;

DetailPanel = connect(
	map_state_to_props,
	map_dispatch_to_props
)(DetailPanel)

// add i18n context for the language
DetailPanel = withTranslation()(DetailPanel)

export default DetailPanel