import { createContext } from "react";

/*
	simple context, to hold all the json data
	because we do not want to have all the data
	in redux store. It can be, that some components
	use this data, that's why we do not want to
	give it down to the component tree
*/
const JSONDataContext = createContext();

export default JSONDataContext;
