import React from 'react';
import { useTranslation } from 'react-i18next';
import Basetact from "./Basetact"

const Direction = (props) => {
    const { t } = useTranslation();

    // translations
    let t_direction = t('Richtung')
    let t_departure_minute = t('Abfahrtsminute')

    let basetact_list = []
    for (let obj of props.basetacts){
        basetact_list.push(
            <Basetact basetact={obj} key={`basetact-${obj.code}`}/>
        )
    }

    return (
        <>
            <div className="direction-title">
                <h3><span className="text">{t_direction} {props.direction.label}</span></h3>
                <div className="label-departure-minute">
                    <span className="text">{t_departure_minute}</span>
                </div>
            </div>
            {basetact_list}
        </>
    )
}

export default Direction