// types
// ui
export const LOADER_GLOBAL_SHOW = 'LOADER_GLOBAL_SHOW'
export const LOADER_GLOBAL_HIDE = 'LOADER_GLOBAL_HIDE'
export const RESIZE_WINDOW = 'RESIZE_WINDOW'

// panels
export const TOGGLE_PANEL_DETAIL_OPEN = 'TOGGLE_PANEL_DETAIL_OPEN' // transition start to open
export const TOGGLE_PANEL_DETAIL_IS_OPEN = 'TOGGLE_PANEL_DETAIL_IS_OPEN' // transition end, is open
export const TOGGLE_PANEL_LEFT_OPEN = 'TOGGLE_PANEL_LEFT_OPEN' // transition start to open
export const TOGGLE_PANEL_LEFT_IS_OPEN = 'TOGGLE_PANEL_LEFT_IS_OPEN' // transition end, is open
export const TOGGLE_PANEL_DETAIL_CONTENT_IS_OPEN = 'TOGGLE_PANEL_DETAIL_CONTENT_IS_OPEN' // NO transition end callback

export const TOGGLE_PANEL_START_OVERLAY_OPEN = 'TOGGLE_PANEL_START_OVERLAY_OPEN' // init transition
export const TOGGLE_PANEL_START_OVERLAY_IS_OPEN = 'TOGGLE_PANEL_START_OVERLAY_IS_OPEN' // transition end callback
export const TOGGLE_PANEL_FULL_OVERLAY_AUTOCOMPLETE_NORESULT_IS_OPEN = 'TOGGLE_PANEL_FULL_OVERLAY_AUTOCOMPLETE_NO_RESULT_IS_OPEN' // NO transition end callback

export const SET_PANEL_HEADER_HEIGHT = 'SET_PANEL_HEADER_HEIGHT'

// open instances
export const TOGGLE_INSTANCE_AUTOCOMPLETE_MAIN_HEADER_OPEN = 'TOGGLE_INSTANCE_AUTOCOMPLETE_MAIN_HEADER_OPEN'
export const TOGGLE_INSTANCE_AUTOCOMPLETE_PANEL_LEFT_OPEN = 'TOGGLE_INSTANCE_AUTOCOMPLETE_PANEL_LEFT_OPEN'
export const TOGGLE_INSTANCE_AUTOCOMPLETE_START_OVERLAY_OPEN = 'TOGGLE_INSTANCE_AUTOCOMPLETE_START_OVERLAY_OPEN'
export const TOGGLE_INSTANCE_AUTOCOMPLETE_PANEL_DETAIL_STATION_OPEN = 'TOGGLE_INSTANCE_AUTOCOMPLETE_PANEL_DETAIL_STATION_OPEN'

// data
export const SET_MAP_LOADED = 'SET_MAP_LOADED';
export const SET_JSON_LOADED = 'SET_JSON_LOADED';

// functions

// ui
export const loader_global_show = () => {
	return { type: LOADER_GLOBAL_SHOW };
}

export const loader_global_hide = () => {
	return { type: LOADER_GLOBAL_HIDE };
}

export const resize_window = (width, height, client_type) => {
	return { type: RESIZE_WINDOW, width: width, height: height, client_type: client_type };
}

// panels

export const toggle_panel_detail_open = (panel_detail_open) => {
	return { type: TOGGLE_PANEL_DETAIL_OPEN, panel_detail_open: panel_detail_open }
}

export const toggle_panel_detail_is_open = (panel_detail_is_open, panel_detail_space) => {
	return { type: TOGGLE_PANEL_DETAIL_IS_OPEN, panel_detail_is_open: panel_detail_is_open, panel_detail_space:  panel_detail_space}
}

export const toggle_panel_left_open = (panel_left_open) => {
	return { type: TOGGLE_PANEL_LEFT_OPEN, panel_left_open: panel_left_open }
}

export const toggle_panel_left_is_open = (panel_left_is_open, panel_left_space) => {
	return { type: TOGGLE_PANEL_LEFT_IS_OPEN, panel_left_is_open: panel_left_is_open, panel_left_space: panel_left_space }
}

// content collapsible/expandable on mobile (showing Map when collapsed)
export const toggle_panel_detail_content_is_open = (panel_detail_content_is_open) => {
	return { type: TOGGLE_PANEL_DETAIL_CONTENT_IS_OPEN, panel_detail_content_is_open: panel_detail_content_is_open}
}


export const toggle_panel_start_overlay_open = (panel_start_overlay_open) => {
	return { type: TOGGLE_PANEL_START_OVERLAY_OPEN, panel_start_overlay_open: panel_start_overlay_open }
}

export const toggle_panel_start_overlay_is_open = (panel_start_overlay_is_open) => {
	return { type: TOGGLE_PANEL_START_OVERLAY_IS_OPEN, panel_start_overlay_is_open: panel_start_overlay_is_open }
}

export const toggle_panel_full_overlay_autocomplete_noresult_is_open = (panel_full_overlay_autocomplete_noresult_is_open) => {
	return { type: TOGGLE_PANEL_FULL_OVERLAY_AUTOCOMPLETE_NORESULT_IS_OPEN, panel_full_overlay_autocomplete_noresult_is_open: panel_full_overlay_autocomplete_noresult_is_open }
}


export const set_panel_header_height = (panel_header_height) => {
	return { type: SET_PANEL_HEADER_HEIGHT, panel_header_height: panel_header_height }
}


// open instances
export const toggle_instance_autocomplete_main_header_open = (autocomplete_main_header_open) => {
	return { type: TOGGLE_INSTANCE_AUTOCOMPLETE_MAIN_HEADER_OPEN, autocomplete_main_header_open: autocomplete_main_header_open }
}
export const toggle_instance_autocomplete_panel_left_open = (autocomplete_panel_left_open) => {
	return { type: TOGGLE_INSTANCE_AUTOCOMPLETE_PANEL_LEFT_OPEN, autocomplete_panel_left_open: autocomplete_panel_left_open }
}
export const toggle_instance_autocomplete_start_overlay_open = (autocomplete_start_overlay_open) => {
	return { type: TOGGLE_INSTANCE_AUTOCOMPLETE_START_OVERLAY_OPEN, autocomplete_start_overlay_open: autocomplete_start_overlay_open }
}
export const toggle_instance_autocomplete_panel_detail_station_open = (autocomplete_panel_detail_station_open) => {
	return { type: TOGGLE_INSTANCE_AUTOCOMPLETE_PANEL_DETAIL_STATION_OPEN, autocomplete_panel_detail_station_open: autocomplete_panel_detail_station_open }
}

// data

export const set_map_loaded = () => {
	return { type: SET_MAP_LOADED };
}

export const set_json_loaded = () => {
	return { type: SET_JSON_LOADED };
}
