import React, {useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next';
import Direction from "./Direction"
import TimeTable from "./TimeTable";
import DetailHeaderItem from "./DetailHeaderItem"
import AutoComplete from "./AutoComplete"
import ToggleDetailContentOpen from "./ToggleDetailContentOpen"
import JSONDataContext from "../../context";
import {build_router_infos, resolve_url} from "../../utils/Router";
import PerfectScrollbar from 'react-perfect-scrollbar'
import {ReactComponent as SBBSwapDirection} from '../../assets/svg/sbb_swap_direction.svg'
import {push} from "connected-react-router";
import {ReactComponent as ButtonArrowRight} from '../../assets/svg/arrow_right.svg'
import {SBB_ONLINE_TIMETABLE_URL} from "../../utils/AppConfig"


const DetailStation = (props) => {
    const { t, i18n } = useTranslation();
	const dispatch = useDispatch()
	const pathname = useSelector(state => state.router.location.pathname);
	const router_infos = build_router_infos(pathname)
    const context = useContext(JSONDataContext)

    let detail_header_items = []
    let content_list = []
    let direction_list = []
    let station_origin_label = undefined
    let station_destination_label = undefined
    let title_destination_string = t('Alle Richtungen')
    let toggle_detail_content_title = t('Nächste Abfahrten und Grundtakt anzeigen')

	const swap_direction_clicked = () => {
        let params = { from_slug: router_infos.to_slug, to_slug: router_infos.from_slug }
        const url = resolve_url('station_from_to', params)
        dispatch(push(url))
	}

	const origin_data = context.general.all_stations.find(x => x.code === router_infos.from_slug)
    let destination_data = undefined
    if (router_infos.to_slug !== undefined) {
        destination_data = context.general.all_stations.find(x => x.code === router_infos.to_slug)
    }

    const external_sbb_timetable_clicked = () => {
	    // Todo Matomo record
        let url = SBB_ONLINE_TIMETABLE_URL[i18n.language]+'?von='+origin_data.label
        if(station_destination_label !== undefined){
            url += '&nach='+station_destination_label
        }
	    window.open(url,'_blank');
    }

    const type = 'from'
    const station_title = station_origin_label = origin_data.label
    detail_header_items.push(
        <div key={`${type}-detail-header-item`} className="detail-header-item">
            <DetailHeaderItem key={`${type}_station`}
                              type={type}
                              station_title={station_title}/>
        </div>
    )

    if (destination_data !== undefined) {
        const type = "to"
        const station_title = station_destination_label = destination_data.label
        title_destination_string = station_title
        detail_header_items.push(
            <div key={`${type}-detail-header-item`} className="detail-header-item">
                <DetailHeaderItem key={`${type}_station`}
                                  type={type}
                                  station_title={station_title}/>
            </div>
        )
        detail_header_items.push(
            <button key="swap-direction" className="swap-direction" onClick={swap_direction_clicked}>
                <SBBSwapDirection />
            </button>
        )
    }else{
        station_destination_label = undefined
        detail_header_items.push(
            <div key="ac-detail-header-item" className="detail-header-item">
                <div className="destination-selector">
                    <AutoComplete key="ac-detail-panel" type="detail-panel" instance_name="autocomplete_panel_detail_station" />
                </div>
            </div>
        )
    }

    content_list = []
    content_list.push(
            <h1 key="title-h1">
                <span className="text">{t('Direktverbindungen Fernverkehr')}</span>
                <br/><span className="origin">{station_origin_label}</span>
                <span className="visuallyhidden"> {t('nach')} </span>
                <span className="arrow ">→</span>
                <span className="destination ">{title_destination_string}</span>
            </h1>
    )
    content_list.push(
            <h2 key="title-nextdep">
                <span className="text">{t('Nächste Abfahrten')}</span>
            </h2>
    )

    if(router_infos.type === 'station'){
        content_list.push(
                <TimeTable key="timetable" />
        )
    }


    // get detail data from JSON
    if (router_infos.type === 'station' && router_infos.to_slug === undefined) {

        const origin_json = context.origins[router_infos.from_slug]
        if (origin_json === undefined) {
            console.error('missing json.origin data for station code: ', router_infos.from_slug)
        } else {
            for (const obj of origin_json.directions) {
                direction_list.push(
                    <Direction direction={obj.direction} basetacts={obj.basetacts}
                               key={`direction-${obj.direction.code}`}/>
                )
            }
        }

    } else if (router_infos.type === 'station' && router_infos.to_slug !== undefined) {

        const origin_json = context.origins[router_infos.from_slug]
        if (origin_json === undefined) {
            console.error('missing json.origin data for station code: ', router_infos.from_slug)
        } else {
            const origin_destination_json = origin_json.connected_stations[router_infos.to_slug]
            if (origin_destination_json === undefined) {
                // Case "Keine Direktverbindungen"  Beispiel Thun -> Moutier
                content_list = []
                content_list.push(
                            <h1 key="title-h1" className="message">
                                <span className="text">{t("Keine Direktverbindungen im Fernverkehr.")}</span>
                            </h1>
                )
                content_list.push(
                            <div key="box-more-connections" className="box content-box">
                                <header className="box-header">
                                    <h3>
                                        <span className="text">{t("Weitere Verbindungen.")}</span>
                                    </h3>
                                </header>
                                <div className="box-body">
                                    <div className="box-content-item">
                                        <div className="box-content-item">
                                            <p className="text">
                                                {t("Der Online-Fahrplan liefert alle Varianten mit Umsteigen und Verbindungen des Regionalverkehrs.")}
                                            </p>
                                        </div>
                                        <ul className="content-link-list">
                                            <li className="content-link-item">
                                                <button className="content-link-button" onClick={external_sbb_timetable_clicked}>
                                                    <span className="text">{t("Fahrplan")}</span>
                                                    <ButtonArrowRight />
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                )

            } else {
                let obj = {
                    "direction": {"code": router_infos.to_slug, "label": station_destination_label},
                    "basetacts": origin_destination_json.basetacts
                }

                direction_list.push(
                    <Direction direction={obj.direction} basetacts={obj.basetacts}
                               key={`direction-${obj.direction.code}`}/>
                )
            }
        }
    }

    if(direction_list.length > 0){
        content_list.push(<h2 key="title-grundtakt"><span className="text">{t('Grundtakt')}</span></h2>)
    }

    return (
        <>
            <header ref={props.main_panel_header_ref} id="detail-panel-header">
                {detail_header_items}
                <ToggleDetailContentOpen toggle_detail_content_title={toggle_detail_content_title}/>
            </header>
            <PerfectScrollbar className="detail-content-scroll-container" containerRef={(ref) => {props.set_scroll_ref_current(ref)}}>
                <div className="detail-content">
                    {content_list}
                    {direction_list}
                    <div className="content-disclaimer">
                        <span className="text">{t("Alle Angaben ohne Gewähr.")}</span>
                    </div>
                </div>
            </PerfectScrollbar>

        </>
    )
}

export default DetailStation