/*
	import some overrides for later use
*/

import {
	DEV_MATOMO_TRACKING_DISABLED,
	DEV_MATOMO_BASE_URL,
	DEV_MATOMO_SITE_ID,
	DEV_MATOMO_USER_ID,
	DEV_MATOMO_SECURE_COOKIE,
	DEV_JSON_DATA_URL,
    DEV_STATIC_DATA_URL,
	DEV_GEOPS_API_URL,
	DEV_SITE_DOMAIN_NAME_INFO_URL,
	GET_DEV_404_URL,
} from './AppConfigLocal'

export const SBB_ONLINE_TIMETABLE_URL = {
	'de': 'https://www.sbb.ch/de/kaufen/pages/fahrplan/fahrplan.xhtml',
	'fr': 'https://www.sbb.ch/fr/acheter/pages/fahrplan/fahrplan.xhtml',
	'it': 'https://www.sbb.ch/it/acquistare/pages/fahrplan/fahrplan.xhtml',
	'en': 'https://www.sbb.ch/en/buying/pages/fahrplan/fahrplan.xhtml'
}

/*
	Router specific config vars
	
	all routes match and resolve
	centralized.
	
	The match part is needed to search
	for matches and to setup all "routes"
	in App. use resolve_match_url from
	utils.Router to handle that
	
	use resolve_url from utils.Router 
	to build the needed url with the right arguments.
*/

export const URL_DATA = {
	'de': {
		'match': {
			'home': '/de/',
			'line': '/de/linie/:line_slug',
			'station_from': '/de/bahnhof/:from_slug',
			'station_from_to': '/de/bahnhof/:from_slug/ziel/:to_slug'
		},
		'resolve': (params={}) => {
			const {
				line_slug,
				from_slug,
				to_slug
			} = params
			return {
				'home': '/de/',
				'line': `/de/linie/${line_slug}`,
				'station_from': `/de/bahnhof/${from_slug}`,
				'station_from_to': `/de/bahnhof/${from_slug}/ziel/${to_slug}`
			}
		}
	},
	'fr': {
		'match': {
			'home': '/fr/',
			'line': '/fr/ligne/:line_slug',
			'station_from': '/fr/gare/:from_slug',
			'station_from_to': '/fr/gare/:from_slug/cible/:to_slug'
		},
		'resolve': (params={}) => {
			const {
				line_slug,
				from_slug,
				to_slug
			} = params
			return {
				'home': '/fr/',
				'line': `/fr/ligne/${line_slug}`,
				'station_from': `/fr/gare/${from_slug}`,
				'station_from_to': `/fr/gare/${from_slug}/cible/${to_slug}`
			}
		}
	},
	'it': {
		'match': {
			'home': '/it/',
			'line': '/it/linea/:line_slug',
			'station_from': '/it/stazione/:from_slug',
			'station_from_to': '/it/stazione/:from_slug/bersaglio/:to_slug'
		},
		'resolve': (params={}) => {
			const {
				line_slug,
				from_slug,
				to_slug
			} = params
			return {
				'home': '/it/',
				'line': `/it/linea/${line_slug}`,
				'station_from': `/it/stazione/${from_slug}`,
				'station_from_to': `/it/stazione/${from_slug}/bersaglio/${to_slug}`
			}
		}
	},
	'en': {
		'match': {
			'home': '/en/',
			'line': '/en/line/:line_slug',
			'station_from': '/en/station/:from_slug',
			'station_from_to': '/en/station/:from_slug/target/:to_slug'
		},
		'resolve': (params={}) => {
			const {
				line_slug,
				from_slug,
				to_slug
			} = params
			return {
				'home': '/en/',
				'line': `/en/line/${line_slug}`,
				'station_from': `/en/station/${from_slug}`,
				'station_from_to': `/en/station/${from_slug}/target/${to_slug}`
			}
		}
	}
}

/*
	Component Instances
*/
export const AUTOCOMPLETE_INSTANCE_NAMES = ['autocomplete_main_header', 'autocomplete_panel_left', 'autocomplete_start_overlay', 'autocomplete_panel_detail_station']


/*
	UI specific config var. Mostly breakpoint definitions, and colors
*/

export const BREAKPOINTS = {
	mobile: 767,
	medium_min: 768,
	medium_max: 1024,
	desktop: 1025
}

export const DISABLE_ELEMENT_COLOR = '#CECECE'

export const SHOW_API_LOADER_AFTER_MS = 0 //100

/*
	Matomo Tracker specific
*/

export let MATOMO_TRACKING_DISABLED = false // optional, false by default. Makes all tracking calls no-ops if set to true.
export let MATOMO_BASE_URL = 'https://matomo-sbb.pokus.ch/'
export let MATOMO_SITE_ID = 2 // in NODE_ENV production, this is overriden, after getting site_info from django in App.js
export let MATOMO_USER_ID = undefined // optional, default value: `undefined`.
export let MATOMO_SECURE_COOKIE = true // needed for https
export const MATOMO_HB_ACTIVE = false // heartbeat enabled
export const MATOMO_HB_SECONDS = 10 // heartbeat intervall, seconds
export const MATOMO_LINK_TRACKING = false // should be disabled for SPA
if(process.env.NODE_ENV === 'development') {
	MATOMO_TRACKING_DISABLED = DEV_MATOMO_TRACKING_DISABLED
	MATOMO_BASE_URL = DEV_MATOMO_BASE_URL
	MATOMO_SITE_ID = DEV_MATOMO_SITE_ID
	MATOMO_USER_ID = DEV_MATOMO_USER_ID
	MATOMO_SECURE_COOKIE = DEV_MATOMO_SECURE_COOKIE
}
export const MATOMO_DOMAIN_ID_DICT = {
	'network.sbb.ch': 2, // production
	'sbb.pokus.ch': 1, // staging
	'default': 1 // default to staging, but only in NODE_ENV production!
}

/*
	Map specific config vars
*/
export const INITIAL_ZOOM = 0.7; // initial zoom, perhaps this needs a calculation
export const MAX_ZOOM_IN = 2.1; // max zoom in
export const MAX_ZOOM_OUT = 0.01; // max zoom out, never reached, because the max zoom out is restricted if the map would not cover the canvas

// export const MIN_MAP_ANIMATION_TIME_DESKTOP = 500 // min animation time in ms
// export const MAX_MAP_ANIMATION_TIME_DESKTOP = 1700 // max animaton time in ms
//
// export const MIN_MAP_ANIMATION_TIME_MOBILE = 250 // min animation time in ms
// export const MAX_MAP_ANIMATION_TIME_MOBILE = 1500 // max animaton time in ms

export const MIN_MAP_ANIMATION_TIME_DESKTOP = 1500 // min animation time in ms
export const MAX_MAP_ANIMATION_TIME_DESKTOP = 2500 // max animaton time in ms

export const MIN_MAP_ANIMATION_TIME_MOBILE = 750 // min animation time in ms
export const MAX_MAP_ANIMATION_TIME_MOBILE = 2000 // max animaton time in ms


// export const INITIAL_ZOOM_MOVE_DELAY = 500 // wait until moving
// export const INITIAL_ZOOM_MOVE_TIME_DESKTOP = 500 // animation duration
// export const INITIAL_ZOOM_MOVE_TIME_MOBILE = 500 // animation duration
// export const INITIAL_ZOOM_MOVE_STEP_DESKTOP = 0.1 // zoom step in
// export const INITIAL_ZOOM_MOVE_STEP_MOBILE = 0.05 // zoom step in

export const INITIAL_ZOOM_MOVE_DELAY = 600 // wait until moving
export const INITIAL_ZOOM_MOVE_TIME_DESKTOP = 2000 // animation duration
export const INITIAL_ZOOM_MOVE_TIME_MOBILE = 2000 // animation duration
export const INITIAL_ZOOM_MOVE_STEP_DESKTOP = 0.18 // zoom step in
export const INITIAL_ZOOM_MOVE_STEP_MOBILE = 0.3 // zoom step in


export const COLOR_ANIMATION_TIME = 150 // color change animation time (lines, labels)

export const ADDITIONAL_BOUND_ZOOM_SPACE_DESKTOP = 200; // add this to bound zoom calculation to not be on the canvas border
export const ADDITIONAL_BOUND_ZOOM_SPACE_MOBILE = 150; // add this to bound zoom calculation to not be on the canvas border
export const ADDITIONAL_SINGLE_BOUND_ZOOM_SPACE_DESKTOP = 1000; // add this to bound zoom calculation to not be on the canvas border
export const ADDITIONAL_SINGLE_BOUND_ZOOM_SPACE_MOBILE = 500; // add this to bound zoom calculation to not be on the canvas border

export const ADDITIONAL_VISIBLE_SPACE_MARGIN_DESKTOP = 60 // add this as visible space margin, for the object is visible in canvas check
export const ADDITIONAL_VISIBLE_SPACE_MARGIN_MOBILE = 10 // add this as visible space margin, for the object is visible in canvas check

export const DEFAULT_ANIMATION_EASING = 'easeInOutQuart'
export const SWITZERLAND_BACKGROUND_OBJ_ID = 'switzerland-background'
export const PINCH_STEP_FACTOR = 40 // factor to multiplicate the scale result
export const TAP_MAX_DIFF = 10 // max difference between tap coordinates to recognize click on a element

/*
	site domain name info url
*/
export let SITE_DOMAIN_NAME_INFO_URL = '/site_info/'
if(process.env.NODE_ENV === 'development') {
	SITE_DOMAIN_NAME_INFO_URL = DEV_SITE_DOMAIN_NAME_INFO_URL
}


/*
	JSON data specific config vars
*/

export let STATIC_DATA_URL = '/static/'
export let JSON_DATA_URL = '/static/netzplan.json'
export let GEOPS_API_URL = '/geops_api/'
if(process.env.NODE_ENV === 'development') {
	JSON_DATA_URL = DEV_JSON_DATA_URL
    STATIC_DATA_URL = DEV_STATIC_DATA_URL
	GEOPS_API_URL = DEV_GEOPS_API_URL
}

// special case as function
export const GET_404_URL = (lang_code) => {
	if(process.env.NODE_ENV === 'development') {
		return GET_DEV_404_URL(lang_code)
	} else {
		return `/${lang_code}/404.html`
	}
}
