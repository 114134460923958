import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'

const DetailEmpty = (props) =>  {

    return (
        <>
            <header ref={props.main_panel_header_ref}>

            </header>
            <PerfectScrollbar className="detail-content-scroll-container" ref={props.scroll_container_ref}>
                <div className="detail-content">

                </div>
            </PerfectScrollbar>
        </>
    )
}

export default DetailEmpty