import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {STATIC_DATA_URL} from "../../utils/AppConfig";
import {ReactComponent as SBBInfoIcon} from '../../assets/svg/sbb_info_icon.svg'
import {ReactComponent as SBBPlusIcon} from '../../assets/svg/sbb_plus_icon.svg'
import {ReactComponent as SBBMinusIcon} from '../../assets/svg/sbb_minus_icon.svg'
import BasetactStop from "./BasetactStop";

const Basetact = (props) => {
    const { t, i18n } = useTranslation();

    const { basetact } = props

    const [detailOpenClass, setDetailOpenClass] = useState("");

    const toggleBasetactDetail = () => {
        setDetailOpenClass(detailOpenClass === "" ? " basetact-detail-open" : "");
    }

    // translations
    let t_hidden_departure_minute = t('Abfahrtsminute')
    let t_hidden_close_basetact_details = t('Details zum Grundtakt schliessen')
    let t_hidden_notice_for_basetact = t('Hinweise zu Grundtakt')
    let t_hidden_basetact_stops = t('Haltestellenliste zu Grundtakt')

    let label_list = []
    for(let line_label of basetact.line_labels){
        label_list.push(
            <div className="line-label" key={line_label}>
                <div className="svg-wrapper">
                    <img src={`${STATIC_DATA_URL}line_labels/label_${line_label}.svg`} alt={`Line ${line_label}`} />
                </div>
            </div>
        )
    }

    let info_icon = ''
    if(basetact.number_info_normal || basetact.number_info_warning){
        let warning_class = basetact.number_info_warning? ' info-icon-warning': '';
        info_icon = (<div className={`info-icon ${warning_class}`}>
                        <SBBInfoIcon className="svg-info-i" />
                    </div>
        )
    }

    let infomessage_list = []
    let ctr1 = 0
    for(let infomessage of basetact.infomessages){
        let class_warning = infomessage.type === 'warning'? ' warning': '';
        infomessage_list.push(
            <li key={ctr1}><span className={`text ${class_warning}`}>{infomessage.text[i18n.language]}</span></li>
        )
        ctr1 += 1
    }

    let basetact_stop_list = []
    let ctr2 = 0
    for(let basetact_stop of basetact.lineflowstops){
        basetact_stop_list.push(
            <BasetactStop basetact_stop={basetact_stop} key={ctr2} />
        )
        ctr2 += 1
    }

    return (
        <div className={`basetact ${detailOpenClass}`}>
            <div className="basetact-header">
                <div className="basetact-labels">

                    <div className="line-labels">
                        {label_list}
                    </div>
                    <div className="basetact-label">
                        <h4 className="text">
                            {basetact.label}
                        </h4>
                    </div>
                </div>
                <div className="infomessage-icon">
                    {info_icon}
                </div>
                <div className="departure-minute">
                    <span className="visuallyhidden">{t_hidden_departure_minute}</span>
                    <span className="text">{basetact.departure_minute}</span>
                </div>
                <div className="toggle-basetact-detail-visual">
                    <div className="plus-icon">
                        <div className="circle"></div>
                        <SBBPlusIcon className="svg-plus" />
                    </div>
                    <div className="minus-icon">
                        <div className="circle"></div>
                        <SBBMinusIcon className="svg-minus" />
                    </div>
                </div>
                <button className="button-toggle-basetact-detail" aria-controls="basetact-1" aria-expanded="false" onClick={toggleBasetactDetail}>
                    <span className="visuallyhidden">{t_hidden_close_basetact_details}</span>
                </button>
            </div>
            <div className="basetact-body" id="basetact-1">
                <h5 className="visuallyhidden">{t_hidden_notice_for_basetact}</h5>
                <ul className="infomessages">
                    {infomessage_list}
                </ul>

                <h5 className="visuallyhidden">{t_hidden_basetact_stops}</h5>
                <ul className="basetact-stops">
                    {basetact_stop_list}
                </ul>
            </div>
        </div>
    )
}

export default Basetact