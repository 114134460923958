import React from 'react'
import { connect } from 'react-redux'
import {MatomoContext} from '@datapunt/matomo-tracker-react';
import HeaderPanel from './panel/HeaderPanel'
import LeftPanel from './panel/LeftPanel'
import DetailPanel from './panel/DetailPanel'
import StartOverlay from './panel/StartOverlay'
import GlobalLoader from './GlobalLoader'
import MapBox from './map/MapBox';
import NotFoundRedirect from './NotFoundRedirect'
import FlyingFocus from './FlyingFocus'
import FullOverlayAutocompleteNoResult from './panel/FullOverlayAutocompleteNoResult'
import {build_router_infos} from '../utils/Router'
import JSONDataContext from '../context'


import {
    toggle_panel_detail_open, 
	toggle_panel_start_overlay_is_open,
	loader_global_hide,
	set_json_loaded
} from '../actions'

/* 
	Wraps Page and combines the two contexts and makes
	them available through props. Faster solution
	and less code than merge the contexts
*/
const PageWithContext = () => {
	return (
		<JSONDataContext.Consumer>
			{json => (
				<MatomoContext.Consumer>
					{matomo_tracker => (
						<Page json={json} matomo_tracker={matomo_tracker} />
					)}
				</MatomoContext.Consumer>
			)}
		</JSONDataContext.Consumer>
  );
}

class Page extends React.Component { 
	
	constructor(props) {
		super(props);
		this.state = {
			a11y_class: '', // additional class for page, flying focus specific
			initial_tracking_done: false // make it possible to track the initial call (and not do it all over again)
		}
	}
	
	componentDidUpdate(prev_props) {
		const {
			panel_detail_open,
			panel_start_overlay_is_open,
			map_loaded,
			json_loaded,
			pathname
		} = this.props
		
		const router_infos = build_router_infos(pathname)
		
		if(map_loaded && json_loaded) {
			// @TODO: this is not needed for mobiles?
			if(!panel_detail_open && (router_infos.type === 'station' || router_infos.type === 'line' )) {
				this.props.toggle_panel_detail_open(true)
			}
			if(panel_detail_open && (router_infos.type === 'home' || router_infos.type === 'not_found')) {
				this.props.toggle_panel_detail_open(false)
			}
			
			// track page view
			if(this.props.matomo_tracker !== undefined && (pathname !== prev_props.pathname || !this.state.initial_tracking_done)) {
				// console.log('track page view!')
				this.props.matomo_tracker.trackPageView()
				if(!this.state.initial_tracking_done) {
					this.setState({
						initial_tracking_done: true
					})
				}
			}
		}
		
		// check if the context is given, and if json is not loaded
		if(this.props.json !== undefined && !json_loaded) {
			this.props.set_json_loaded()
			this.props.loader_global_hide()
		}

		if(router_infos.type !== 'home' && panel_start_overlay_is_open){
		    this.props.toggle_panel_start_overlay_is_open(false)
        }
	}
	
	set_a11y_class_state = (a11y_class) => {
		this.setState({
			a11y_class: a11y_class
		})
	}
	
	/*
		Check if the data exists, else redirect in render
	*/
	check_404 = (pathname) => {
		const router_infos = build_router_infos(pathname)
		let is_404 = false
		if(router_infos.type !== 'home') {
			if(router_infos.type === 'station') {
				if(router_infos.from_slug !== undefined) {
					const origin_data = this.props.json.general.all_stations.find(x => x.code === router_infos.from_slug)
					if (origin_data === undefined) {
						is_404 = true
					}
				}
				if(router_infos.to_slug !== undefined) {
					const origin_data = this.props.json.general.all_stations.find(x => x.code === router_infos.to_slug)
					if (origin_data === undefined) {
						is_404 = true
					}
				}
			} else if(router_infos.type === 'line') {
				const origin_data = this.props.json.general.all_lines.find(x => x.code === router_infos.line_slug)
				if (origin_data === undefined) {
					is_404 = true
				}
			}
		}
		return is_404
	}
	
	render = () => {
		
		const {
			json_loaded,
			pathname,
			loader_global_show_count, 
			panel_detail_open,
            panel_detail_content_is_open,
			panel_left_open,
            panel_start_overlay_is_open,
            panel_full_overlay_is_open,
		} = this.props
		
		const {
			a11y_class
		} = this.state
		
		let page_class = a11y_class !== ''? `page ${a11y_class}`: 'page'
		if(loader_global_show_count > 0) {
			page_class += " global-loader-run"
		}
		if(panel_left_open) {
			page_class += " panel-left-open"
		}
		if(panel_detail_open) {
			page_class += " detail-open"
		}
		if(panel_detail_content_is_open){
		    page_class += " detail-content-open"
        }
		if(panel_start_overlay_is_open) {
			page_class += " start-overlay-open"
		}
		if(panel_full_overlay_is_open) {
			page_class += " full-overlay-open"
		}
		
		// check if we have a 404
		let components = [
			<GlobalLoader key="globalloader" />,
		]
		if(json_loaded) {
			let is_404 = this.check_404(pathname)
			if(!is_404) {
				components = [
					...components,
					<HeaderPanel key="header-panel" />,
					<MapBox key="mapbox" />,
					<LeftPanel key="left-panel" />,
					<StartOverlay key="start-overlay" />,
					<DetailPanel key="detail-panel" />,
					<FullOverlayAutocompleteNoResult key="full-overlay-autocomplete-no-result"/>
				]
			} else {
				components = [
					<NotFoundRedirect key="not-found-redirect"/>
				]
			}
		}
		
		return (
			<div id="page-container" className={page_class}>
				{components}
				<FlyingFocus set_a11y_class_state={this.set_a11y_class_state} a11y_class={a11y_class} />
			</div>
		)
	}
}

const map_state_to_props = state => {
	return {
		loader_global_show_count: state.general.ui.loader_global_show_count,
		panel_detail_open: state.general.panels.panel_detail_open,
        panel_detail_content_is_open: state.general.panels.panel_detail_content_is_open,
        panel_start_overlay_is_open: state.general.panels.panel_start_overlay_is_open,
        panel_full_overlay_is_open: state.general.panels.panel_full_overlay_is_open,
		panel_left_open: state.general.panels.panel_left_open,
		map_loaded: state.general.data.map_loaded,
		json_loaded: state.general.data.json_loaded,
		pathname: state.router.location.pathname
	}
}

const map_dispatch_to_props = dispatch => {
	return {
		toggle_panel_detail_open: panel_detail_open => {
			dispatch(toggle_panel_detail_open(panel_detail_open));
		},
		toggle_panel_start_overlay_is_open: panel_start_overlay_is_open => {
			dispatch(toggle_panel_start_overlay_is_open(panel_start_overlay_is_open));
		},
		loader_global_hide: () => {
			dispatch(loader_global_hide())
		},
		set_json_loaded: () => {
			dispatch(set_json_loaded())
		},
	}
}

Page = connect(
	map_state_to_props,
	map_dispatch_to_props
)(Page)

export default PageWithContext