import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'


import {
	LOADER_GLOBAL_SHOW,
	LOADER_GLOBAL_HIDE,
	SET_MAP_LOADED,
	SET_JSON_LOADED,
	RESIZE_WINDOW,
	TOGGLE_PANEL_DETAIL_OPEN,
	TOGGLE_PANEL_DETAIL_IS_OPEN,
	TOGGLE_PANEL_LEFT_OPEN,
	TOGGLE_PANEL_LEFT_IS_OPEN,
	TOGGLE_PANEL_DETAIL_CONTENT_IS_OPEN,
	TOGGLE_PANEL_START_OVERLAY_OPEN,
	TOGGLE_PANEL_START_OVERLAY_IS_OPEN,
	TOGGLE_PANEL_FULL_OVERLAY_AUTOCOMPLETE_NORESULT_IS_OPEN,
	TOGGLE_INSTANCE_AUTOCOMPLETE_MAIN_HEADER_OPEN,
	SET_PANEL_HEADER_HEIGHT,
	TOGGLE_INSTANCE_AUTOCOMPLETE_PANEL_LEFT_OPEN,
	TOGGLE_INSTANCE_AUTOCOMPLETE_START_OVERLAY_OPEN, TOGGLE_INSTANCE_AUTOCOMPLETE_PANEL_DETAIL_STATION_OPEN
} from './actions';

const initial_state = {
	ui: { // ui related unspecific
		loader_global_show_count: 0, // indicates if the global loader is shown > 0, or hidden = 0
		window_width: 0,
		window_height: 0,
		client_type: 'desktop' // mobile | tablet | desktop -> see breakpoints in AppConfig
	},
	panels: { // panels
		panel_detail_open: false, // indicates if the detail panel should open -> start open transition
		panel_detail_is_open: false, // is open -> end transition
		panel_detail_space: 0, // width or height in px, used to see if objects on map are overlapped by it
		panel_left_open: false, // indicates if the left panel should open > start open transition
		panel_left_is_open: false, // is open -> end transition
		panel_left_space: 0, // width/height in px, used to see if objects on map are overlapped by it
		panel_header_height: 0, // height of the header panel
        panel_detail_content_is_open: true, // mobile default: show basetact, not map
        panel_start_overlay_open: false, // Intro on Home fade in
        panel_start_overlay_is_open: false, // Intro on Home on Transition End
        panel_full_overlay_autocomplete_noresult_is_open: false, // Full Overlay for Dialog (eg. autocomplete -> no result found for string 'jahsgdjsd')
	},
	instances_open: { // open instances
		autocomplete_main_header: false, // indicates if the autocomplete should open
		autocomplete_panel_left: false, // indicates if the autocomplete should open
		autocomplete_start_overlay: false, // indicates if the autocomplete should open
		autocomplete_panel_detail_station: false, // indicates if the autocomplete should open
	},
	data: { // data, like loading indicator, later also partly json
		map_loaded: false, // indicates if the svg map is loaded
		json_loaded: false // indicates if the json data is loaded (from static, or dev endpoint)
	}
}

function react_app_reducer(state = initial_state, action) {
	switch (action.type) {
		case LOADER_GLOBAL_SHOW:
			const new_show_count = state.ui.loader_global_show_count + 1
			return Object.assign({}, state, {
				ui: {
					...state.ui,
					loader_global_show_count: new_show_count
				}
			});
		case LOADER_GLOBAL_HIDE:
			let new_hide_count = state.ui.loader_global_show_count - 1
			if(new_hide_count < 0) {
				new_hide_count = 0;
			}
			return Object.assign({}, state, {
				ui: {
					...state.ui,
					loader_global_show_count: new_hide_count
				}
			});
		case TOGGLE_PANEL_DETAIL_OPEN:
			return Object.assign({}, state, {
				panels: {
					...state.panels,
					panel_detail_open: action.panel_detail_open
				}
			});
		case TOGGLE_PANEL_DETAIL_IS_OPEN:
			return Object.assign({}, state, {
				panels: {
					...state.panels,
					panel_detail_is_open: action.panel_detail_is_open,
					panel_detail_space: action.panel_detail_space
				}
			});		
		case TOGGLE_PANEL_LEFT_OPEN:
			return Object.assign({}, state, {
				panels: {
					...state.panels,
					panel_left_open: action.panel_left_open
				}
			});
		case TOGGLE_PANEL_LEFT_IS_OPEN:
			return Object.assign({}, state, {
				panels: {
					...state.panels,
					panel_left_is_open: action.panel_left_is_open,
					panel_left_space: action.panel_left_space
				}
			});
        case TOGGLE_PANEL_DETAIL_CONTENT_IS_OPEN:
			return Object.assign({}, state, {
				panels: {
					...state.panels,
					panel_detail_content_is_open: action.panel_detail_content_is_open
				}
			});
        case TOGGLE_PANEL_START_OVERLAY_OPEN:
			return Object.assign({}, state, {
				panels: {
					...state.panels,
					panel_start_overlay_open: action.panel_start_overlay_open
				}
			});
        case TOGGLE_PANEL_START_OVERLAY_IS_OPEN:
			return Object.assign({}, state, {
				panels: {
					...state.panels,
					panel_start_overlay_is_open: action.panel_start_overlay_is_open
				}
			});
        case TOGGLE_PANEL_FULL_OVERLAY_AUTOCOMPLETE_NORESULT_IS_OPEN:
			return Object.assign({}, state, {
				panels: {
					...state.panels,
					panel_full_overlay_autocomplete_noresult_is_open: action.panel_full_overlay_autocomplete_noresult_is_open
				}
			});
		case SET_PANEL_HEADER_HEIGHT:
			return Object.assign({}, state, {
				panels: {
					...state.panels,
					panel_header_height: action.panel_header_height
				}
			});

		case TOGGLE_INSTANCE_AUTOCOMPLETE_MAIN_HEADER_OPEN:
			return Object.assign({}, state, {
				instances_open: {
					...state.instances_open,
					autocomplete_main_header: action.autocomplete_main_header_open
				}
			});
		case TOGGLE_INSTANCE_AUTOCOMPLETE_PANEL_LEFT_OPEN:
			return Object.assign({}, state, {
				instances_open: {
					...state.instances_open,
					autocomplete_panel_left: action.autocomplete_panel_left_open
				}
			});
		case TOGGLE_INSTANCE_AUTOCOMPLETE_START_OVERLAY_OPEN:
			return Object.assign({}, state, {
				instances_open: {
					...state.instances_open,
					autocomplete_start_overlay: action.autocomplete_start_overlay_open
				}
			});
		case TOGGLE_INSTANCE_AUTOCOMPLETE_PANEL_DETAIL_STATION_OPEN:
			return Object.assign({}, state, {
				instances_open: {
					...state.instances_open,
					autocomplete_panel_detail_station: action.autocomplete_panel_detail_station_open
				}
			});


		case RESIZE_WINDOW:
			return Object.assign({}, state, {
				ui: {
					...state.ui,
					window_width: action.width,
					window_height: action.height,
					client_type: action.client_type
				}
			});
		case SET_MAP_LOADED:
			return Object.assign({}, state, {
				data: {
					...state.data,
					map_loaded: true
				}
			});
		case SET_JSON_LOADED:
			return Object.assign({}, state, {
				data: {
					...state.data,
					json_loaded: true
				}
			});
	    default:
			return state
	}
}

export const history = createBrowserHistory()

const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	general: react_app_reducer,
})

// combine the redux store dev tools with the normal compose to apply middleware
// normal would be to just use compose instead of composeEnhancers

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
	createRootReducer(history), // root reducer with router state
	composeEnhancers(
		applyMiddleware(
			routerMiddleware(history), // for dispatching history actions
		),
	)
);

export default store;
