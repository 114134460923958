import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// usr local saved language files
import translation_de from './i18n/de/translation.json';
import translation_fr from './i18n/fr/translation.json';
import translation_it from './i18n/it/translation.json';
import translation_en from './i18n/en/translation.json';

// use the same settings as django would use for cookie and session name
const language_detector_options = {
	order: ['path', 'cookie', 'sessionStorage', 'navigator'],
	lookupQuerystring: 'lng',
	lookupCookie: 'django_language',
	lookupSessionStorage: 'django_language',
	lookupFromPathIndex: 0,
	lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['cookie']
}

const language_detector = new LanguageDetector(null, language_detector_options);

const resources = {
	de: {
		translation: translation_de
	},
	fr: {
		translation: translation_fr
	},
	it: {
		translation: translation_it
	},
	en: {
		translation: translation_en
	}
};

i18n
	.use(language_detector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	// if language detection is used, do not use the lng variable, it will mess up things
	.init({
		resources,
		whitelist: ['de','fr','it','en'],
		defaultNS: 'translation',
		fallbackLng: 'de',
		keySeparator: true,
		debug: false, // set true to find missing keys to translate in console output

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		}
	});


export default i18n;
