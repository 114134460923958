import React from 'react'

class FlyingFocus extends React.Component { 
	
	constructor(props) {
		super(props);
		this.FF_DURATION = 150
		this.moving_id = 0
		this.keydown_time = 0
		this.prev_focused = null
		this.flying_focus_ref = React.createRef()
		
		// bind this to several handlers
		this.document_keydown = this.document_keydown.bind(this)
		this.document_clicked = this.document_clicked.bind(this)
		this.document_focus = this.document_focus.bind(this)
		this.document_blur = this.document_blur.bind(this)
	}
	
	componentDidMount = () => {
		document.addEventListener('click', this.document_clicked)
		document.addEventListener('keydown', this.document_keydown, false)
		document.addEventListener('focus', this.document_focus, true)
		document.addEventListener('blur', this.document_blur, true)
	}
	
	/* flying-focus specific
	*/
	_is_just_pressed = () => {
			return Date.now() - this.keydown_time < 42
	}
	
	_on_end = () => {
		if (!this.moving_id) {
			return;
		}

		clearTimeout(this.moving_id);
		this.moving_id = 0;
		this.flying_focus_ref.current.classList.remove('flying-focus_visible');
		this.prev_focused.classList.remove('flying-focus_target');
		this.prev_focused = null;
	}
	
	_offset_of = (elem) => {
		const rect       = elem.getBoundingClientRect()
		const clientLeft = document.clientLeft || document.body.clientLeft
		const clientTop  = document.clientTop  || document.body.clientTop
		const scrollLeft = window.pageXOffset || document.scrollLeft || document.body.scrollLeft
		const scrollTop  = window.pageYOffset || document.scrollTop  || document.body.scrollTop
		const left       = rect.left + scrollLeft - clientLeft
		const top        = rect.top  + scrollTop  - clientTop

		return {
			top: top || 0,
			left: left || 0
		}
	}
	
	// if the element has aria selected attribute, set it to true and all others from this parent to false
	_set_aria_selected = (elem) => {
		const aria_selected = elem.getAttribute('aria-selected')
		if(aria_selected !== null) {
			const parent_node = elem.parentNode
			for(const child of parent_node.children) {
				child.setAttribute('aria-selected', false)
				child.setAttribute('tabindex', "-1")
			}
			elem.setAttribute('aria-selected', true)
			elem.setAttribute('tabindex', "0")
		}
	}
	
	// simple check if an element is hidden.
	// @TODO: does not work for some stuff in panel left right now (tab has the same problem)
	_el_is_hidden = (el) => {
		if(el.offsetParent === null) {
			return true
		} else {
			let style = window.getComputedStyle(el);
    		if(style.display === 'none') {
				return true
			} else if(style.visibility === 'hidden') {
				return true
			}
		}
		return false
	}
	
	/*
		focus handler helpers
	*/
	_next_focusable = (focusables) => {
		let ctr = 0
		for(let el of focusables) {
			if(el === document.activeElement) {
				let next_ctr = ctr + 1
				if(next_ctr < (focusables.length - 0)) {
 					return focusables[next_ctr]
				} else { // first
					return focusables[0]
				}
			}
			ctr += 1
		}
	}
	
	_prev_focusable = (focusables) => {
		let ctr = 0
		for(let el of focusables) {
			if(el === document.activeElement) {
				let prev_ctr = ctr -1
				if(prev_ctr >= 0) {
					return focusables[prev_ctr]
				} else { // last
					return focusables[focusables.length - 1]
				}
			}
			ctr += 1
		}
	}
	
	/* 
		main keydown for flying focus control
	*/
	document_keydown = (event) => {
		var code = event.which;
		// Show animation only upon Tab or Arrow keys press.
		if (code === 9 || (code > 36 && code < 41)) {
			this.keydown_time = Date.now()
			if(this.props.a11y_class === '' || this.props.a11y_class === 'f') {
				this.props.set_a11y_class_state('focus-source-key')
			}
			if(code > 36 && code < 41) { // arrows, check if there is an element active
				//let focusables = [...document.querySelectorAll('button, input, [tabindex]:not([tabindex="-1"])')].filter(el => !this._el_is_hidden(el))
				let focusables = [...document.querySelectorAll('.aria-select-focusable')].filter(el => !this._el_is_hidden(el))

                if(!document.body.contains(document.activeElement) ||
					document.body === document.activeElement) { // nothing has focus
					if(focusables.length) { // focus first element
						focusables[0].focus()
					}
				} else { // there is something in focus
					if(code === 40) { // arrow down and left = next
						const next_el = this._next_focusable(focusables)
						if(next_el !== undefined) {
						    event.preventDefault()
							next_el.focus()
						} else { // can happen on close button, if element gets closed, release focus!
							document.activeElement.blur()
						}
					} else if (code === 38) { // arrow up and right = previous
						const prev_el = this._prev_focusable(focusables)
						if(prev_el !== undefined) {
						    event.preventDefault()
							prev_el.focus()
						} else { // can happen on close button, if element gets closed, release focus!
							document.activeElement.blur()
						}
					}
				}
			}
		} 
	}
	
	document_focus = (event) => {
		const target = event.target;
		
		// console.log('target is now: ',target)
		
		if (target.id === 'flying-focus') {
			return;
		}
		
		if(document.body.contains(target)) {
			const offset = this._offset_of(target);
			this.flying_focus_ref.current.style.left = offset.left + 'px';
			this.flying_focus_ref.current.style.top  = offset.top + 'px';
			this.flying_focus_ref.current.style.width = target.offsetWidth + 'px';
			this.flying_focus_ref.current.style.height = target.offsetHeight + 'px';
			this._set_aria_selected(target)
		}

		if (this._is_just_pressed()) {
			return;
		}
		
		if(document.body.contains(target)) {
			this._on_end();
			target.classList.add('flying-focus_target');
			this.flying_focus_ref.current.classList.add('flying-focus_visible');
			this.prev_focused = target;
			this.moving_id = setTimeout(this._on_end, this.FF_DURATION);
		}
	}
	
	document_blur = (event) => {
		this._on_end()
	}
	
	document_clicked = () => {
		if(this.props.a11y_class !== '') {
			this.props.set_a11y_class_state('f')
		}
	}
	
	/* other react
	*/
	
	render = () => {
		const flying_focus_inline_style = {
			WebkitTransitionDuration: this.FF_DURATION / 1000 + 's'
		}
		
		return (
			<flying-focus id='flying-focus' style={flying_focus_inline_style} ref={this.flying_focus_ref} />
		)
	}
}

export default FlyingFocus