import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import JSONDataContext from "../../context"
import {build_router_infos} from "../../utils/Router"
import axios from 'axios'
import {ReactComponent as ButtonArrowRight} from '../../assets/svg/arrow_right.svg'

import {
    STATIC_DATA_URL,
    GEOPS_API_URL,
    SHOW_API_LOADER_AFTER_MS, SBB_ONLINE_TIMETABLE_URL
} from '../../utils/AppConfig'

class TimeTable extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			time_table_list: [], // data from service 
			show_loader: false, // if the loader is shown
			api_error: false, // some error happened (not used right now)
            is_layout_expanded: false
		}
		// normal var, no need to rerender on change
		this.service_request_run = false
        this.station_origin_label = undefined
        this.station_destination_label = undefined
		
		// bind this to set timeout callback
		this.show_loader_check = this.show_loader_check.bind(this)
	}
	
	componentDidMount() {
		const {
			pathname
		} = this.props
		
		const router_infos = build_router_infos(pathname)
		// console.log('get_api_data on mount')

        this.get_api_data(router_infos)
        // this.setState({show_loader: true}) // debug: force loader to stay 1s
        // setTimeout(this.get_api_data, 1000, router_infos)  // debug: force loader to stay 1s
	}
	
	componentDidUpdate = (prev_props) => {
		const {
			pathname
		} = this.props
		
		const router_infos = build_router_infos(pathname)
		const prev_router_infos = build_router_infos(prev_props.pathname)
		
		if(router_infos.from_slug !== prev_router_infos.from_slug || 
		   router_infos.to_slug !== prev_router_infos.to_slug) {
			// console.log('get_api_data on did update')

            this.get_api_data(router_infos)
            this.set_layout_collapsed()
            // this.setState({show_loader: true}) // debug: force loader to stay 1s
            // setTimeout(this.get_api_data, 1000, router_infos)  // debug: force loader to stay 1s
		}
	}

	external_sbb_timetable_clicked = () => {
	    const { i18n } = this.props
	    // Todo Matomo record
        let url = SBB_ONLINE_TIMETABLE_URL[i18n.language]+'?von='+this.station_origin_label
        if(this.station_destination_label !== undefined){
            url += '&nach='+this.station_destination_label
        }
	    window.open(url,'_blank');
    }
	
	show_loader_check = () => {
		if(this.service_request_run && !this.state.show_loader) {
			// console.log('delay done, show loader')
			this.setState({show_loader: true})
		}
	}

	set_layout_collapsed = () => {
        this.setState({is_layout_expanded: false})
    }
	set_layout_expanded = () => {
        this.setState({is_layout_expanded: true})
    }
    getclass_layout_expanded_state = () => {
	    return this.state.is_layout_expanded? ' next-departures-expanded': '';
    }
	
	get_api_data = (router_infos) => {
		// console.log('get_api_data: fetch data')
		let params = {}
		const station_from_data = this.context.general.all_stations.find(x => x.code === router_infos.from_slug)
		if(station_from_data !== undefined) {
			params.uic = ''+station_from_data.uic
            this.station_origin_label = station_from_data.label
		} else {
			// console.error('uic not found!')
			return
		}
		if(router_infos.to_slug !== undefined) {
			const station_to_data = this.context.general.all_stations.find(x => x.code === router_infos.to_slug)
			if(station_to_data !== undefined) {
				params.destination_uic = ''+station_to_data.uic
                this.station_destination_label = station_to_data.label
			} else {
				// console.error('destination_uic not found!')
                this.station_destination_label = undefined
				return
			}
		}else{
		    this.station_destination_label = undefined
        }
		// console.log('params: ',params)
		this.service_request_run = true
		setTimeout(this.show_loader_check, SHOW_API_LOADER_AFTER_MS)
		axios.post(GEOPS_API_URL, params) // fetch is not supported by ie11
		.then((tr) => {
			this.service_request_run = false
			this.setState({show_loader: false, time_table_list: tr.data})
		}, (error) => {
			console.error(error)
			this.service_request_run = false
			this.setState({show_loader: false, error: true})
		})
	}
	
	render = () => {
        const { t } = this.props

        const empty_row = (key) => (
                                    <div key={key} className="divtable-row empty-row">
                                        <div className="divtable-cell" role="cell"></div>
                                    </div>
        )

        const loader_markup = (message) => (
                                <div key="loader" className="loader">
                                    <div key="divtable-next-departures" className="divtable-next-departures" role="table" aria-label="next departures"
                                     aria-describedby="next_departures_table_desc" aria-rowcount="10">
                                        <div key="table-head" className="divtable-row" role="row">
                                            <div className="divtable-columnheader" role="columnheader" aria-sort="none"></div>
                                            <div className="divtable-columnheader" role="columnheader" aria-sort="none"></div>
                                            <div className="divtable-columnheader" role="columnheader" aria-sort="none"></div>
                                            <div className="divtable-columnheader" role="columnheader" aria-sort="none"></div>
                                        </div>
                                        {empty_row(1)}
                                        <div key="2" className="divtable-row empty-row">
                                            <div className="divtable-cell" role="cell">
                                                <span className="text">{message}</span>
                                            </div>
                                        </div>
                                        {empty_row(3)}
                                    </div>
                                </div>
        )

		let result = []
		if(this.state.show_loader) {
			result.push(<div key="going-to-load">
                            {loader_markup('')}
			</div>)
		} else if(this.state.time_table_list.length && !this.state.api_error) {

			let list = []
			let ctr = 0
			for(const obj of this.state.time_table_list) {
                let departure_time = obj.timetabledTimeLocal
                let class_warning_delayed = ''
                // console.log('timetabledTimeLocal', obj.timetabledTimeLocal)
                // console.log('estimatedTimeLocal', obj.estimatedTimeLocal)
                if (obj.estimatedTimeLocal !== null && obj.estimatedTimeLocal !== obj.timetabledTimeLocal) {
                    const date_obj_timetabled_time_local = new Date(obj.timetabledTimeLocal);
                    const date_obj_estimated_time_local = new Date(obj.estimatedTimeLocal);
                    const time_diff = date_obj_estimated_time_local.getTime() - date_obj_timetabled_time_local.getTime();
                    const minute_diff = time_diff / 1000 / 60
                    // console.log('MM minute_diff', minute_diff)

                    if(minute_diff > 1){
                        departure_time = obj.estimatedTimeLocal
                        class_warning_delayed = 'warning-delayed'
                    }
                }
				let cdt = new Date(departure_time)
				let intl_dt = new Intl.DateTimeFormat('de-CH', {
					hour: '2-digit',
					minute: '2-digit'
				}).format(cdt)

                // prevent missing label svg -> show label string if not known
                let line_label = ''
                let t_line = t("Linie")
                const valid_labels = ['EC','ICE','TGV','RJX','TE2','IRE','EN','NJ','IC','IR','PE','RE']
                const valid_label_start = ['IC','IR','RE','PE']
                if(valid_labels.indexOf(obj.lineName) > -1 || valid_label_start.indexOf(obj.lineName.slice(0,2)) > -1) {
                    line_label = (
                        <div className="svg-wrapper">
                            <img src={`${STATIC_DATA_URL}line_labels/label_${obj.lineName}.svg`}
                                 alt={`${t_line} ${obj.lineName}`}/>
                        </div>
                    )

                // Manual Label Replacements (OPJ Api von SBB leifert die richtigen Productcodes IR/RE etc, aber falsche LineLabels)
                }else if(obj.lineName === 'REG' || obj.lineName === 'S2' || obj.lineName === 'S9' || obj.lineName === 'S11') {
                    line_label = (
                        <div className="svg-wrapper">
                            <img src={`${STATIC_DATA_URL}line_labels/label_RE.svg`}
                                 alt={`${t_line} RE`}/>
                        </div>
                    )
                }else if(obj.lineName === 'BEX' || obj.lineName === 'GEX' || obj.lineName === 'GPX') {
                    line_label = (
                        <div className="svg-wrapper">
                            <img src={`${STATIC_DATA_URL}line_labels/label_PE.svg`}
                                 alt={`${t_line} PE`}/>
                        </div>
                    )
                }else if(obj.lineName === 'VAE') {
                    line_label = (
                        <div className="svg-wrapper">
                            <img src={`${STATIC_DATA_URL}line_labels/label_IR.svg`}
                                 alt={`${t_line} IR`}/>
                        </div>
                    )
                }else if(obj.lineName === '30') {
                    line_label = (
                        <div className="svg-wrapper">
                            <img src={`${STATIC_DATA_URL}line_labels/label_PE30.svg`}
                                 alt={`${t_line} PE30`}/>
                        </div>
                    )
                }else if(obj.lineName === 'S31') {
                    line_label = (
                        <div className="svg-wrapper">
                            <img src={`${STATIC_DATA_URL}line_labels/label_IR65.svg`}
                                 alt={`${t_line} IR65`}/>
                        </div>
                    )
                }else if(obj.lineName === 'S5') {
                    line_label = (
                        <div className="svg-wrapper">
                            <img src={`${STATIC_DATA_URL}line_labels/label_IR66.svg`}
                                 alt={`${t_line} IR66`}/>
                        </div>
                    )
                // }else if(obj.lineName === 'RE2') {
                //     line_label = (
                //         <div className="svg-wrapper">
                //             <img src={`${STATIC_DATA_URL}line_labels/label_RE2.svg`}
                //                  alt={`${t_line} RE2`}/>
                //         </div>
                //     )
                // }else if(obj.lineName === 'RE4') {
                //     line_label = (
                //         <div className="svg-wrapper">
                //             <img src={`${STATIC_DATA_URL}line_labels/label_RE4.svg`}
                //                  alt={`${t_line} RE4`}/>
                //         </div>
                //     )
                }else if(obj.lineName === '3') {
                    line_label = (
                        <div className="svg-wrapper">
                            <img src={`${STATIC_DATA_URL}line_labels/label_IRE3.svg`}
                                 alt={`${t_line} IRE3`}/>
                        </div>
                    )
                }else if(obj.lineName === 'RE80') {
                    line_label = (
                        <div className="svg-wrapper">
                            <img src={`${STATIC_DATA_URL}line_labels/label_RE80.svg`}
                                 alt={`${t_line} RE80`}/>
                        </div>
                    )
                }else{
                    // rubbish -> Ausgabe von original-Label in plaintext falls kein Match mit Icons und Replacement
                    line_label = (
                        <span className="text">{obj.lineName}</span>
                    )
                }

				list.push(<div key={`item-${ctr}`} className="divtable-row" role="row" aria-rowindex={ctr}>
                    <div className="divtable-cell" role="cell">
                        {line_label}
                    </div>
                    <div className="divtable-cell" role="cell">
                        <span className={`text ${class_warning_delayed}`}>{intl_dt}</span>
                    </div>
                    <div className="divtable-cell" role="cell">
                        <span className="text">{obj.destinationText}</span>
                    </div>
                    <div className="divtable-cell" role="cell">
                        <span className="text">{obj.plannedQuay}</span>
                    </div>
				</div>)

				ctr += 1

			}

			// fill up with empty rows -> need at least 3 rows for layout consistency
			while(ctr < 3){
			    list.push(empty_row(ctr))
                ctr++
            }

			result.push(<div key="result-table">
                            <div key="expandable-area" className="expandable-area">
                                <div key="description" className="visuallyhidden" id="next_departures_table_desc">{t('Nächste Abfahrten ab Bahnhof')} {this.station_origin_label}</div>
                                <div key="divtable-next-departures" className="divtable-next-departures" role="table" aria-label="next departures"
                                     aria-describedby="next_departures_table_desc" aria-rowcount="10">
                                    <div key="table-head" className="divtable-row" role="row">
                                        <div className="divtable-columnheader" role="columnheader" aria-sort="none">
                                            <span className="text visuallyhidden">{t("Linie")}</span>
                                        </div>
                                        <div className="divtable-columnheader" role="columnheader" aria-sort="none">
                                            <span className="text">{t("Ab")}</span>
                                        </div>
                                        <div className="divtable-columnheader" role="columnheader" aria-sort="none">
                                            <span className="text">{t("Nach")}</span>
                                        </div>
                                        <div className="divtable-columnheader" role="columnheader" aria-sort="none">
                                            <span className="text">{t("Gleis")}</span>
                                        </div>
                                    </div>
                                    {list}
                                </div>
                                <div key="show-less-next-departures" className="link-button-wrapper show-less-next-departures">
                                    <button className="link-button linkstyle-underline" tabIndex="-1" onClick={this.set_layout_collapsed}>
                                        {t("Weniger anzeigen")}
                                    </button>
                                </div>

                                <div key="box-fahrplan" className="box content-box">
                                    <header className="box-header">
                                        <h3>
                                            <span className="text">{t("Weitere Verbindungen.")}</span>
                                        </h3>
                                    </header>
                                    <div className="box-body">
                                        <div className="box-content-item">
                                            <p className="text">
                                                {t("Der Online-Fahrplan liefert alle Varianten mit Umsteigen und Verbindungen des Regionalverkehrs.")}
                                            </p>
                                        </div>
                                        <div className="box-content-item">
                                            <ul className="content-link-list">
                                                <li key="item-1" className="content-link-item">
                                                    <button className="content-link-button" onClick={this.external_sbb_timetable_clicked}>
                                                        <span className="text">{t("Fahrplan")}</span>
                                                        <ButtonArrowRight />
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div key="show-more-next-departures" className="link-button-wrapper show-more-next-departures">
                                <button className="link-button linkstyle-underline" onClick={this.set_layout_expanded}>
                                            {t("Spätere anzeigen")}
                                </button>
                            </div>
                        </div>
            )
		} else if(!this.state.time_table_list.length && !this.state.api_error) {
		    // api fine, but empty list
            result.push(<div key="empty-result">
                            {loader_markup(t('Aktuell keine nächsten Abfahrten verfügbar.'))}
                            <div key="box-fahrplan" className="box content-box">
                                <header className="box-header">
                                    <h3>
                                        <span className="text">{t("Weitere Verbindungen.")}</span>
                                    </h3>
                                </header>
                                <div className="box-body">
                                    <div className="box-content-item">
                                        <p className="text">
                                            {t("Der Online-Fahrplan liefert alle Varianten mit Umsteigen und Verbindungen des Regionalverkehrs.")}
                                        </p>
                                    </div>
                                    <div className="box-content-item">
                                        <ul className="content-link-list">
                                            <li key="item-1" className="content-link-item">
                                                <button className="content-link-button" onClick={this.external_sbb_timetable_clicked}>
                                                    <span className="text">{t("Fahrplan")}</span>
                                                    <ButtonArrowRight />
                                                </button>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
			            </div>)

        } else if(this.state.error) {
			result.push(<div key="empty-result">
                            {loader_markup('error loading data')}
			            </div>)
		}
		return (
            <div key="next-departures" className={`next-departures ${this.getclass_layout_expanded_state()}`}>
				{result}
			</div>
		)
	}
}

const map_state_to_props = state => {
	return {
		pathname: state.router.location.pathname
	}
}

TimeTable.contextType = JSONDataContext;

TimeTable = connect(
	map_state_to_props,
	null
)(TimeTable)

// add i18n context for the language
TimeTable = withTranslation()(TimeTable)

export default TimeTable;