import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import Map from './Map';
import JSONDataContext from "../../context"
import {build_router_infos} from "../../utils/Router"

import {
	loader_global_hide
} from '../../actions'

const map = new Map(); // make it global for this element to never loose reference

class MapBox extends React.Component {
	/*
		Wrapper around the Fabricjs
		Map.
	*/
	
	constructor(props) {
		super(props);
		// reference to container, where we add the map element
		this.map_container = React.createRef();
		
		// we need a local state for the mouse handler setup tracking
		// just use map_loaded and json_loaded can lead to a race condition
		this.state = {
			setup_mouse_handlers_done: false
		}
	}
	
	componentDidMount() {
		/*
			add the class canvas element to the map_container reference
			and call setup
		*/
		this.map_container.current.appendChild(map.canvas_element);
		map.setup(this.props.i18n.language);
		
	}
	
	componentDidUpdate = (prev_props) => {
		/*
			the main work happens here...
		*/
		const {
			map_loaded,
			json_loaded,
			pathname,
			panel_detail_is_open,
			panel_start_overlay_is_open,
			panel_detail_space,
			panel_header_height,
			client_type,
			window_width,
			window_height
		} = this.props
		
		const router_infos = build_router_infos(pathname)
		const prev_router_infos = build_router_infos(prev_props.pathname)

		map.update_client_type(client_type)
		map.update_router_infos(router_infos)
		map.update_panel_spaces(panel_detail_space, panel_header_height)
		map.update_start_panel_state(panel_start_overlay_is_open)
		
		/* 
		   	we can not use prev_props.json_loaded, we need a local state
		   	else we have a race condition which can lead to unset json
		   	and unset mouse handlers
		*/
		if (json_loaded && !this.state.setup_mouse_handlers_done && map_loaded) {
			//actually we should only add over and out mouse handlers if not mobile
			map.setup_mouse_handlers(this.context.general.all_lines, this.context.general.all_stations)
			this.setState({setup_mouse_handlers_done: true})
		}
		
		// all loaded
		if(json_loaded && map_loaded) {
			if(window_width !== prev_props.window_width || window_height !== prev_props.window_height) {
				if(client_type === 'desktop') { // only for desktop. Else we always rezoom when the user opens the screen keyboard
					map.zoom_check_map_resize(this.map_container.current.offsetWidth, this.map_container.current.offsetHeight)
				}
			}
			
			if(router_infos.type === 'home') {
				if(!prev_props.map_loaded) {
					map.initial_zoom_move()
				}
				if(prev_router_infos.type === 'station') {
					map.reset_all_highlight_station()
					map.reset_line_highlight()
				} else if(prev_router_infos.type === 'line') {
					map.reset_line_highlight()
				}
			}
			if(router_infos.type === 'line') {
				if(prev_router_infos.type === 'station') {
					map.reset_all_highlight_station()
				}
				map.highlight_line(router_infos.line_slug);
				if(panel_detail_is_open) { // animation are started when the detail panel is open
					map.zoom_highlighted_line(router_infos.line_slug);
				}
			}
			if(router_infos.type === 'station') {
                const from_station_origins_json = this.context.origins[router_infos.from_slug]
				let from_to_highlighted_tracks_stations = [] // f24+: we use this get the proper zoom_box
                if(from_station_origins_json !== undefined) {
                    map.update_from_station_origins_json(from_station_origins_json)
                } else {
                    map.update_from_station_origins_json({})
                }
                // reset all, does not "blink"
                map.reset_all_highlight_station()
                // do station highlight
                map.highlight_station(router_infos.from_slug, 'from')
                if(router_infos.to_slug !== undefined) {
                    const to_station_origins_json = this.context.origins[router_infos.to_slug]
                    if(to_station_origins_json !== undefined) {
                        map.update_to_station_origins_json(to_station_origins_json)
                    } else {
                        map.update_to_station_origins_json({})
                    }
                    map.highlight_station(router_infos.to_slug, 'to')
                    // highlight all lines connected, both stations
                    map.highlight_all_from_to_station_lines(router_infos.from_slug, router_infos.to_slug)
                } else {
                    // highlight all lines connected, only to "from" station
                    map.highlight_all_from_station_lines()
                }
                if(panel_detail_is_open) { // animation are started when the detail panel is open
                    if(router_infos.to_slug !== undefined) {
						// @Update f24 (Nov 2023): we use all stations around highlighted tracks to find the max stretch values for zoom_box
						if(from_station_origins_json.connected_stations.hasOwnProperty(router_infos.to_slug)){
					 		map.zoom_highlighted_tracks(from_station_origins_json.connected_stations[router_infos.to_slug].track_highlights_affected_stations);
						}else{
							map.zoom_highlighted_stations(router_infos.from_slug, router_infos.to_slug)
						}
                    } else { // single station, check if visible, if not, center and zoom it
                        map.zoom_not_visible_station(router_infos.from_slug)
                    }
                }
			}
		}
	}
	
	render = () => {
		return (
			<div className="map-container" ref={this.map_container}></div>
		)
	}
}

const map_state_to_props = state => {
	return {
		panel_detail_space: state.general.panels.panel_detail_space,
		panel_header_height: state.general.panels.panel_header_height,
		panel_detail_is_open: state.general.panels.panel_detail_is_open,
		panel_start_overlay_is_open: state.general.panels.panel_start_overlay_is_open,
		loader_global_show_count: state.general.ui.loader_global_show_count,
		map_loaded: state.general.data.map_loaded,
		json_loaded: state.general.data.json_loaded,
		pathname: state.router.location.pathname,
		client_type: state.general.ui.client_type,
		window_width: state.general.ui.window_width,
		window_height: state.general.ui.window_height
	}
}

const map_dispatch_to_props = dispatch => {
	return {
		loader_global_hide: () => {
			dispatch(loader_global_hide())
		}
	}
}

MapBox.contextType = JSONDataContext;

// connect to redux store
MapBox = connect(
	map_state_to_props,
	map_dispatch_to_props
)(MapBox)

// add i18n context for the language
MapBox = withTranslation()(MapBox)

export default MapBox;