import React, {useEffect, useState, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import AutoComplete from './AutoComplete'
import {ReactComponent as XClose} from '../../assets/svg/x_close.svg'
import {toggle_panel_start_overlay_open, toggle_panel_start_overlay_is_open} from "../../actions";


const StartOverlay = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch()
    const overlay_open = useSelector(state => state.general.panels.panel_start_overlay_open)
    const overlay_is_open = useSelector(state => state.general.panels.panel_start_overlay_is_open)
    const [is_fading_out, set_is_fading_out] = useState(false);

    let content = []

	const handle_remove_item = useCallback(() => {
        dispatch(toggle_panel_start_overlay_is_open(false))
        set_is_fading_out(false);
	}, [dispatch, set_is_fading_out])

	const fadeOut = useCallback(() => {
		set_is_fading_out(true);
        setTimeout(() => handle_remove_item(), 300)
	}, [set_is_fading_out, handle_remove_item])

    useEffect(() => {
	    if(overlay_open === true) {
			dispatch(toggle_panel_start_overlay_is_open(true))
		}
	    if(overlay_open === false) {
			fadeOut()
		}

    }, [overlay_open, dispatch, fadeOut])

    const close_clicked = () => {
        // do not trigger fadeout directly -> redux state to trigger!
        dispatch(toggle_panel_start_overlay_open(false))
    }

	if(overlay_is_open){
	    content.push(
            <div key="start-overlay"
                 className={`start-overlay  ${is_fading_out ? 'start-overlay-fade-out' : ''}`}>
                <button className="close-handler" onClick={close_clicked}>
                    <span className="visuallyhidden">{t("Intro schliessen")}</span>
                    <div className="close-icon">
                        <div className="circle"></div>
                        <XClose />
                    </div>
                </button>
                <header className="start-overlay-header">
                    <h1 className="title">{t("Direktverbindungen Fernverkehr")}</h1>
                </header>
                <div className="start-overlay-body">
                    <div className="text">
                        {t("Wählen Sie Ihre Linie / Ihren Ausgangsbahnhof in der Karte aus oder benutzen Sie das Eingabefeld.")}
                    </div>
                    <div className="search">
                        <AutoComplete type="panel-left"  instance_name="autocomplete_start_overlay" />
                    </div>
                </div>
            </div>
        )
        content.push(
            <div key="start-manual" className={`start-manual  ${is_fading_out ? 'start-manual-fade-out' : ''}`}>
                <div className="manual-layer">
                    <div className="image-wrapper">
                        <img src="/static/images/zoom_tap_gestures.png" alt="pinch/scroll to zoom, drag to pan" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {content}
        </>
    )

}

export default StartOverlay