import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router'
import {ReactComponent as XClose} from '../../assets/svg/x_close.svg'
import {build_router_infos} from '../../utils/Router'
import {resolve_url} from '../../utils/Router'

const DetailHeaderItem = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch()
	const pathname = useSelector(state => state.router.location.pathname);
	const router_infos = build_router_infos(pathname)
	
	const {
		type,
		station_title,
	} = props
	
	const close_clicked = () => {
		if(type === 'from' && router_infos.to_slug !== undefined) {
			// console.warn('THIS NEEDS SPECIAL HANDLING!')
			const url = resolve_url('home', {}) // home
			dispatch(push(url))
		} else { // normal cases
			if(type === 'from') { // reset to home
				const url = resolve_url('home', {}) // home
				dispatch(push(url))
			} else {
				const url = resolve_url('station_from', {from_slug: router_infos.from_slug})
				dispatch(push(url))
			}
		}
	}
	
	// translations
	let label = ''
	let behig_text = ''
	let close_class = ''
	
	if(type === 'from') {
		label = t('Von')
		behig_text = t('Abfahrtsbahnhof schliessen')
		close_class = "close-origin"
	} else { // to
		label = t('Nach')
		behig_text = t('Zielbahnhof schliessen')
		close_class = "close-destination"
	}
	
	return (
		<div className="detail-title-item station-item">
			<div className="label-title-wrapper">
				<div className="label">
					<span className="text">{label}</span>
				</div>
				<div className="station-title">
					<span className="text">{station_title}</span>
				</div>
			</div>
			<button className={`close-handler ${close_class}`} onClick={close_clicked}>
				<span className="visuallyhidden">{behig_text}</span>
				<div className="close-icon">
				<div className="circle"></div>
					<XClose />
				</div>
			</button>
		</div>
	)
}

export default DetailHeaderItem