import React from 'react'
import { useTranslation } from 'react-i18next'

const LanguageChooser = () => {
	const {i18n} = useTranslation();
	
	const all_lang_codes = ['de', 'fr', 'it', 'en']
	
	const lang_list = all_lang_codes.map((c) => {
		const e_class = c === i18n.language ? 'active': ''
		return <li key={`lang-${c}`}><a className={e_class} href={`/${c}/`}>{c}</a></li>
	})
	
	return (
		<ul>
			{lang_list}
		</ul>

	)
}

export default LanguageChooser