import { matchPath } from 'react-router-dom'
import {URL_DATA} from './AppConfig'
import i18n from '../i18n'

/*
	Assemble the router information
	to a handy format. Do the matching
	from the pathname.
	The matches are not stored in redux
	which is very odd and well known:
	
	https://github.com/supasate/connected-react-router/issues/38
	
	This method needs to be called if there
	is a need to check the params		
*/
export const build_router_infos = (pathname) => {
	let router_infos = {}
	// match all urls and setup the arguments
	const line_match = matchPath(pathname, { path: resolve_match_url('line')})
	const station_from_match = matchPath(pathname, { path: resolve_match_url('station_from')})
	const station_from_to_match = matchPath(pathname, { path: resolve_match_url('station_from_to')})
	router_infos.line_slug = line_match !== null ? line_match.params.line_slug : undefined
	router_infos.from_slug = station_from_match !== null ? station_from_match.params.from_slug : undefined
	if((station_from_match !== null && !station_from_match.isExact) || router_infos.from_slug === undefined) {
		router_infos.from_slug = station_from_to_match !== null ? station_from_to_match.params.from_slug : undefined
		router_infos.to_slug = station_from_to_match !== null ? station_from_to_match.params.to_slug : undefined
	} else {
		router_infos.to_slug = undefined
	}
	const home_match = matchPath(pathname, { path: resolve_match_url('home')})
	// evaluate the type
	if(home_match !== null && home_match.isExact) {
		router_infos.type = 'home'
	} else {
		if(router_infos.line_slug !== undefined) {
			router_infos.type = 'line'
		} else {
			if(router_infos.from_slug !== undefined || router_infos.to_slug !== undefined) {
				router_infos.type = 'station'
			} else {
				router_infos.type = 'not_found'
			}
		}
	}
	return router_infos
}

/*
	return the match url for current
	or a specific language by id
*/
export const resolve_match_url = (id, language=undefined) => {
	let lang = i18n.language
	if(language !== undefined) {
		lang = language
	}
	const url = URL_DATA[lang]['match'][id]
	if(url === undefined) {
		throw SyntaxError('resolve_match_url: There is no url with id: '+id)
	}
	return url
}

/*
	return the url with filled params
	for the current language by id
*/
export const resolve_url = (id, params) => {
	const resolver_data = URL_DATA[i18n.language]['resolve'](params)
	const url = resolver_data[id]
	if(url === undefined) {
		throw SyntaxError('resolve_url: There is no url with id: '+id)
	}
	return url
}