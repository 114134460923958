import {useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { GET_404_URL } from '../utils/AppConfig'
import { useMatomo } from '@datapunt/matomo-tracker-react'

/*
	The only task of this component is to
	do a redirect on mount to a "external"
	404 page to return a proper status code
*/

const NotFoundRedirect = () => {
	const {i18n} = useTranslation();
	const { trackPageView } = useMatomo()
	
	useEffect(() => {
		trackPageView({
			documentTitle: '404',
		})
		const redirect_url = GET_404_URL(i18n.language)
		/* 
			use replace to replace the wrong history entry, else the user has
			to hit the back button twice
		*/
		window.location.replace(redirect_url)
	}, [i18n.language, trackPageView]);
	
	return ([])
}

export default NotFoundRedirect