import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react'
import { ConnectedRouter } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import Page from './components/Page'
import GlobalLoader from './components/GlobalLoader'
import NotFoundRedirect from './components/NotFoundRedirect'
import JSONDataContext from './context'
import {
	loader_global_show,
	loader_global_hide,
	resize_window
} from './actions'
import { 
	BREAKPOINTS,
	JSON_DATA_URL,
	SITE_DOMAIN_NAME_INFO_URL,
	MATOMO_TRACKING_DISABLED,
	MATOMO_BASE_URL,
	MATOMO_SITE_ID,
	MATOMO_DOMAIN_ID_DICT,
	MATOMO_USER_ID,
	MATOMO_HB_ACTIVE,
	MATOMO_HB_SECONDS,
	MATOMO_LINK_TRACKING
} from './utils/AppConfig'
import { resolve_match_url } from './utils/Router'
import { get_cookie } from './utils/Cookies'
import { history } from './reducer'


const App = () => {
	const {i18n} = useTranslation();
	
	// used to set the JSONDataContext Provider value set later
	const [json_data, set_json_data] = useState();
	const [tracker_instance, set_tracker_instance] = useState();
	const dispatch = useDispatch();

	useEffect(() => {
		/* 
			load the json data from the specific url and have it
			ready to publish it to the JSONDataContext.
		*/
		
		/*
			csrftoken handling:
			in production env the cookie is delivered
			from index django view
			in development we catch it over the json
			load.
		*/
		if(process.env.NODE_ENV !== 'development') {
			const csrftoken = get_cookie('csrftoken')
			axios.defaults.headers.post['X-CSRFToken'] = csrftoken;
		} else { // only needed for development, with cross site requests
			axios.defaults.withCredentials = true
		}
		
		dispatch(loader_global_show());
		
		axios.get(JSON_DATA_URL) // fetch is not supported by ie11
		.then((response) => {
			if(process.env.NODE_ENV === 'development') {
				const csrftoken = get_cookie('csrftoken')
				axios.defaults.headers.post['X-CSRFToken'] = csrftoken;
			}
			set_json_data(response.data);
			/* 
				it is not safe to set json to loaded right now
				this is done in mapbox with a special context check
				because the file is big and needs parsing time
				and the set_json_call needs to be processed
				We also dispatch the global loader hide there,
				else the user could access the autocomplete or
				other components which needs json too
			*/
		}, (error) => {
			dispatch(loader_global_hide());
			/* we do not set json to loaded, perhaps there should be an
			error message to the end user: something like visit later */
			console.log(error);
		});
		
		axios.get(SITE_DOMAIN_NAME_INFO_URL) // fetch is not supported by ie11
		.then((response) => {
			const domain = response.data.domain
			let site_id = MATOMO_SITE_ID
			if(process.env.NODE_ENV !== 'development') {
				if(domain in MATOMO_DOMAIN_ID_DICT) {
					site_id = MATOMO_DOMAIN_ID_DICT[domain]
				} else {
					site_id = MATOMO_DOMAIN_ID_DICT['default']
				}
			}
			const tracker_instance = createInstance({
				urlBase: MATOMO_BASE_URL,
				siteId: site_id,
				userId: MATOMO_USER_ID, 
				disabled: MATOMO_TRACKING_DISABLED, 
				heartBeat: {
					active: MATOMO_HB_ACTIVE, 
					seconds: MATOMO_HB_SECONDS
				},
				linkTracking: MATOMO_LINK_TRACKING,
				configurations: { // optional, default value: {}
					// any valid matomo configuration, all below are optional
					disableCookies: true,
					setSecureCookie: false, // set to true on https connection!
					setRequestMethod: 'POST'
			  }
			})
			set_tracker_instance(tracker_instance)
		}, (error) => {
			console.log(error);
		});
		
		
		/*
			update window size if user resizes
			the window
		*/
		const handle_window_resize = () => {
			const w = window.innerWidth
			const h = window.innerHeight
			let client_type = 'desktop'
			if(w <= BREAKPOINTS.mobile) {
				client_type = 'mobile'
			} else if(w >= BREAKPOINTS.medium_min && w <= BREAKPOINTS.medium_max) {
				client_type = 'tablet'
			}
			// console.log('client_type: '+client_type)
			dispatch(resize_window(w, h, client_type));
		}
		
		window.addEventListener("resize", handle_window_resize);
		// initial manual call
		handle_window_resize();
		return () => window.removeEventListener("resize", handle_window_resize);
		
	}, [dispatch]);
	
	return (
		<div className="app-container">
			<MatomoProvider value={tracker_instance}>
			<JSONDataContext.Provider value={json_data}>
				<ConnectedRouter history={history}>
					<Router>
						<GlobalLoader />
						<Switch>
							<Route exact path={resolve_match_url('station_from', 'de')} component={Page} />
							<Route exact path={resolve_match_url('station_from_to', 'de')} component={Page} />
							<Route exact path={resolve_match_url('line', 'de')} component={Page} />
							<Route exact path={resolve_match_url('home', 'de')} component={Page} />
							
							<Route exact path={resolve_match_url('station_from', 'fr')} component={Page} />
							<Route exact path={resolve_match_url('station_from_to', 'fr')} component={Page} />
							<Route exact path={resolve_match_url('line', 'fr')} component={Page} />
							<Route exact path={resolve_match_url('home', 'fr')} component={Page} />
							
							<Route exact path={resolve_match_url('station_from', 'it')} component={Page} />
							<Route exact path={resolve_match_url('station_from_to', 'it')} component={Page} />
							<Route exact path={resolve_match_url('line', 'it')} component={Page} />
							<Route exact path={resolve_match_url('home', 'it')} component={Page} />
							
							<Route exact path={resolve_match_url('station_from', 'en')} component={Page} />
							<Route exact path={resolve_match_url('station_from_to', 'en')} component={Page} />
							<Route exact path={resolve_match_url('line', 'en')} component={Page} />
							<Route exact path={resolve_match_url('home', 'en')} component={Page} />
							
							<Route exact path="/">
								<Redirect to={`/${i18n.language}/`} />
							</Route>
							<Route
								path="/([\w\d-_]+)" // allow all, except something with ".", kiss, this is used for 404.html redirection
								component={NotFoundRedirect} />
						</Switch>
					</Router>
				</ConnectedRouter>
			</JSONDataContext.Provider>
			</MatomoProvider>
		</div>
	);
}

export default App;
