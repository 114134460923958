import React from 'react';
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import {toggle_panel_detail_content_is_open} from "../../actions";

const ToggleDetailContentOpen = (props) => {
    const { t } = useTranslation();
	const dispatch = useDispatch();
    const { toggle_detail_content_title } = props

    const set_detail_content_closed = () => {
	    // console.log('set_detail_content_closed')
		dispatch(toggle_panel_detail_content_is_open(false))
	}
	const set_detail_content_open = () => {
        // console.log('set_detail_content_open')
		dispatch(toggle_panel_detail_content_is_open(true))
	}

    return (
        <div className="mobile-content-toggle-wrap mobile-only">
            <button className="detail-content-toggler button-show-map linkstyle-underline" onClick={set_detail_content_closed}>
                {t('Karte anzeigen')}
            </button>
            <button className="detail-content-toggler button-show-content linkstyle-underline" onClick={set_detail_content_open}>
                {toggle_detail_content_title}
            </button>
        </div>
    )
}

export default ToggleDetailContentOpen