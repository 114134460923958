import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
	toggle_panel_left_is_open
} from '../../actions'

import LanguageChooser from './LanguageChooser'
import AutoComplete from './AutoComplete'
import {ReactComponent as ButtonArrowRight} from '../../assets/svg/arrow_right.svg'
import {useTranslation} from "react-i18next";
import PerfectScrollbar from 'react-perfect-scrollbar'
import {SBB_ONLINE_TIMETABLE_URL} from "../../utils/AppConfig";

const LeftPanel = () => {
	const { t, i18n } = useTranslation();
	const left_panel_ref = useRef(null) // used to get width
	
	const panel_left_open = useSelector(state => state.general.panels.panel_left_open)
	const dispatch = useDispatch()
	
	// called if open or close transition ends
	const on_transition_end = () => {
		let new_panel_left_is_open = panel_left_open ? true : false;
		let new_panel_left_space = new_panel_left_is_open ? left_panel_ref.current.offsetWidth: 0 
		dispatch(toggle_panel_left_is_open(new_panel_left_is_open, new_panel_left_space))
	}

	const external_sbb_timetable_clicked = () => {
	    // Todo Matomo record
        let url = SBB_ONLINE_TIMETABLE_URL[i18n.language]
	    window.open(url,'_blank');
    }
	
	return (

		<nav className="panel-left" onTransitionEnd={on_transition_end} ref={left_panel_ref}>
            <PerfectScrollbar className="nav-content-scrollable" >

                <div className="nav-main">
                    <div className="nav-section nav-section-title">
                        <div className="title">
                            {t("Direktverbindungen Fernverkehr")}
                        </div>
                        <div className="text">
                            {t("Wählen Sie Ihre Linie / Ihren Ausgangsbahnhof in der Karte aus oder benutzen Sie das Eingabefeld.")}
                        </div>

                        <div className="search">
                            <AutoComplete type="panel-left" instance_name="autocomplete_panel_left" />
                        </div>
                    </div>

                </div>
                <div className="nav-bottom">
                    <div className="nav-section nav-section-more-connections">
                        <div className="box">
                            <header className="box-header">
                                <h3>
                                    <span className="text">{t("Alle Verbindungen.")}</span>
                                </h3>
                            </header>
                            <div className="box-body">
                                <div className="box-content-item">
                                    <span className="text">{t("Für detaillierte Fahrplanauskünfte benutzen sie den Online-Fahrplan.")}</span>
                                </div>
                                <div className="box-content-item">
                                    <ul className="content-link-list">
                                        <li className="content-link-item">
                                            <button className="content-link-button" onClick={external_sbb_timetable_clicked}>
                                                <span className="text">{t("Fahrplan")}</span>
                                                <ButtonArrowRight />
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nav-section nav-section-disclaimer">
                                <span className="text">
                                    {t("Disclaimer:")}
                                    <br />{t("disclaimer-valid-info")}
                                    <br />{t("Alle Angaben, Anschlüsse und Einhaltung des Fahrplans ohne Gewähr. Informieren Sie sich kurz vor Abfahrt über mögliche Änderungen.")}
                                    <br />
                                    <br />
                                    <button className="content-link-button ot-sdk-show-settings">
                                            <span className="text">{t("Cookie-Einstellungen")}</span>
                                    </button>
                                </span>

                    </div>
                    <div className="nav-section nav-section-language-selector">
                        <span className="visuallyhidden">{t("Sprache wählen")}</span>
                        <LanguageChooser />
                    </div>
                </div>
            </PerfectScrollbar>

		</nav>
	)
}

export default LeftPanel