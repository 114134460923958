/*
	Autocomplete search helpers
*/

// search in the list for specific matches
export const autocomplete_search = (list, value, type=undefined) => {
	// search for start with
	const start_regex = new RegExp('^'+value, 'i')
	// search for after -
	const after_dash_regex = new RegExp('\\-'+value,'i')
	// search for after whitespace
	const after_ws_regex = new RegExp(' '+value,'i')
	
	const internal_search = (x) => {
		let names = []

		if(x.string_to_search_in.indexOf(',') !== undefined){
			names = x.string_to_search_in.split(',')
		}else{
			names = [x.string_to_search_in]
		}

		// console.log('names', names)

		for(const name of names){
			// search for exact match
			x.exact_match = false
			if(value.toLowerCase() === name.toLowerCase()) {
				x.exact_match = true
				return x
			}
			let match = start_regex.exec(name)
			if(match !== null && match.length) {
				return x
			}
			match = after_dash_regex.exec(name)
			if(match !== null && match.length) {
				return x
			}
			match = after_ws_regex.exec(name)
			if(match !== null && match.length) {
				return x
			}
		}

		return undefined
	}
	
	let result = list.filter((x) => {
		if(type !== undefined) { // only if type matches, type can be "line" | "station"
			if(x.type === type) {
				return internal_search(x)
			} else {
				return undefined
			}
		} else {
			return internal_search(x)
		}
	})
	// console.log('autocomplete_search result:', result)
	return result
}

// extend the result with the specific highlighted part and the info if it is an exact match
// the highlighted_name needs to be set with dangerouslySetInnerHTML, else it is rendered as text
export const autocomplete_highlight_list = (list, value) => {
	// search for start with
	const start_regex = new RegExp('^'+value, 'i')
	// search for after -
	const after_dash_regex = new RegExp('\\-'+value,'i')
	// search for after whitespace
	const after_ws_regex = new RegExp(' '+value,'i')
	
	let result = []
	for(const x of list) {
		// search for exact match
		if(value.toLowerCase() === x.name.toLowerCase()) {
			x.highlighted_name = `<strong>${x.name}</strong>`
			// x.exact_match = true
			result.push(x)
			continue
		}
		let match = start_regex.exec(x.name)
		if(match !== null && match.length) {
			const hp = x.name.slice(0, value.length)
			const ep = x.name.slice(value.length, x.name.length)
			x.highlighted_name = `<strong>${hp}</strong>${ep}`
			// x.exact_match = false
			result.push(x)
			continue
		}
		match = after_dash_regex.exec(x.name)
		if(match !== null && match.length) {
			const sp = x.name.slice(0, (match.index+1))
			const hp = x.name.slice((match.index+1), (match.index+1+value.length))
			const ep = x.name.slice((match.index+1+value.length), x.name.length)
			x.highlighted_name = `${sp}<strong>${hp}</strong>${ep}`
			// x.exact_match = false
			result.push(x)
			continue
		}
		match = after_ws_regex.exec(x.name)
		if(match !== null && match.length) {
			const sp = x.name.slice(0, (match.index+1))
			const hp = x.name.slice((match.index+1), (match.index+1+value.length))
			const ep = x.name.slice((match.index+1+value.length), x.name.length)
			x.highlighted_name = `${sp}<strong>${hp}</strong>${ep}`
			// x.exact_match = false
			result.push(x)
			continue
		}
		x.highlighted_name = x.name
		// x.exact_match = false
		result.push(x)
	}

	const max_results = 9
	if(result.length > max_results){
	    return result.slice(0, max_results);
    }else{
	    return result
    }
}

// search for the best match in a exisiting result list and return it
// if nothing found, return null
export const autocomplete_get_best_match = (result_list) => {
	let best_match_obj = null
	for(const obj of result_list) {
		if(obj.exact_match) {
			best_match_obj = obj
		}
	}
	// if no exact match is given, try the first one
	if(best_match_obj === null && result_list.length) {
		best_match_obj = result_list[0]
	}
	return best_match_obj
}
