import React from 'react';

/*
	as more than one component can show or hide this loader
	we have a counter in the store. if the counter is 0
	the loader is hidden. If the counter is > 0, the loader
	has to be shown
*/

const GlobalLoader = () => {
	return (
        <div className="global-loader">

            <div _ngcontent-kvl-c195="" mode="small"
                 aria-valuetext="Loading small, please wait" _nghost-kvl-c191="" ng-reflect-mode="small"
                 aria-busy="true"
                 className="sbb-loading-small mobile-only">
                <div _ngcontent-kvl-c191="">
                    <div _ngcontent-kvl-c191="">
                        <div _ngcontent-kvl-c191="">
                            <div _ngcontent-kvl-c191=""></div>
                            <div _ngcontent-kvl-c191=""></div>
                            <div _ngcontent-kvl-c191=""></div>
                            <div _ngcontent-kvl-c191=""></div>
                            <div _ngcontent-kvl-c191=""></div>
                        </div>
                    </div>
                </div>
            </div>


            <div _ngcontent-kvl-c195="" mode="big"
                 aria-valuetext="Loading big, please wait" _nghost-kvl-c191="" ng-reflect-mode="big"
                 aria-busy="true"
                 className="sbb-loading-big tablet-plus-only">
                <div _ngcontent-kvl-c191="">
                    <div _ngcontent-kvl-c191="">
                        <div _ngcontent-kvl-c191="">
                            <div _ngcontent-kvl-c191=""></div>
                            <div _ngcontent-kvl-c191=""></div>
                            <div _ngcontent-kvl-c191=""></div>
                            <div _ngcontent-kvl-c191=""></div>
                            <div _ngcontent-kvl-c191=""></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
	)
}

export default GlobalLoader