import React, { useContext } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next';
import ToggleDetailContentOpen from "./ToggleDetailContentOpen"
import JSONDataContext from "../../context";
import {build_router_infos, resolve_url} from "../../utils/Router";
import {ReactComponent as XClose} from '../../assets/svg/x_close.svg'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {push} from "connected-react-router";
import Basetact from "./Basetact";



const DetailLine = (props) =>  {
    const { t } = useTranslation();
	const dispatch = useDispatch()
	const pathname = useSelector(state => state.router.location.pathname);
	const router_infos = build_router_infos(pathname)
    const context = useContext(JSONDataContext)

    const close_clicked = () => {
        const url = resolve_url('home', {}) // home
        dispatch(push(url))
	}

    let basetact_list = []
    let line_label = ''
    let toggle_detail_content_title = t('Grundtakt anzeigen')
    let t_line = t("Linie")


    // @TODO 404: what do we show if the user enters an url which is correct, but the line does not exist?

    const line_data = context.general.all_lines.find(x => x.code === router_infos.line_slug)
    if (line_data !== undefined) {

        line_label = line_data.label
        for (let obj of line_data.basetacts){
            basetact_list.push(
                <Basetact basetact={obj} key={`basetact-${obj.code}`}/>
            )
        }

    } else {
        // console.log('no data for Line:', router_infos.line_slug)
        line_label = 'Line not found for this slug'
    }

    return (
        <>
            <header ref={props.main_panel_header_ref}>
                <div className="detail-header-item">
                    <div className="detail-title-item line-item">
                        <div className="label-title-wrapper">
                            <div className="line-label">
                                <div className="svg-wrapper">
                                    <img src={`/static/line_labels/label_${line_data.code}.svg`} alt={`${t_line} ${line_data.code}`} />
                                </div>
                            </div>
                            <div className="line-title">
                                <span className="text">Linie {line_label}</span>
                            </div>
                        </div>
                        <button className="close-handler close-origin" onClick={close_clicked}>
                            <span className="visuallyhidden">{t("Abfahrtsbahnhof schliessen")}</span>
                            <div className="close-icon">
                                <div className="circle"></div>
                                <XClose/>
                            </div>
                        </button>
                    </div>
                </div>
                <ToggleDetailContentOpen toggle_detail_content_title={toggle_detail_content_title}/>
            </header>
            <PerfectScrollbar className="detail-content-scroll-container" containerRef={(ref) => {props.set_scroll_ref_current(ref)}}>
                <div className="detail-content">
                    <h1>
                        <span className="text">{t('Abfahrtsminuten Grundtakt')}</span>
                        <br/><span className="origin visuallyhidden">{line_label}</span>
                    </h1>
                    <div className="detail-line-basetacts">
                        {basetact_list}
                    </div>
                    <div className="content-disclaimer">
                        <span className="text">{t("Alle Angaben ohne Gewähr.")}</span>
                    </div>
                </div>
            </PerfectScrollbar>
        </>
    )
}

export default DetailLine