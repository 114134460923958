import React, { useState, useContext, useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router'
import {ReactComponent as CloseIcon} from '../../assets/svg/x_close.svg'


import JSONDataContext from '../../context'

import {build_router_infos} from '../../utils/Router'

import {
	autocomplete_search, 
	autocomplete_highlight_list,
	autocomplete_get_best_match
} from '../../utils/Search'

import {resolve_url} from '../../utils/Router'
import {
	toggle_panel_left_open,
	toggle_panel_full_overlay_autocomplete_noresult_is_open,
	toggle_instance_autocomplete_main_header_open,
	toggle_instance_autocomplete_panel_left_open,
	toggle_instance_autocomplete_start_overlay_open,
	toggle_instance_autocomplete_panel_detail_station_open
} from "../../actions";
import {ReactComponent as SearchIcon} from "../../assets/svg/search_icon.svg";

import { AUTOCOMPLETE_INSTANCE_NAMES } from "../../utils/AppConfig"

// valid types for autocomplete are for the moment: header | detail-panel

const AutoComplete = ({ type, instance_name }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch(); // later used for history push
	const pathname = useSelector(state => state.router.location.pathname);
	const is_open = useSelector(state => state.general.instances_open[instance_name]);
	
	// const [is_open, set_is_open] = useState(false);
	const [result_list, set_result_list] = useState([]);

	const router_infos = build_router_infos(pathname) // only used with type detail-panel to get the from_slug

	const instance_toggle_actions = {
		autocomplete_main_header: toggle_instance_autocomplete_main_header_open,
		autocomplete_panel_left: toggle_instance_autocomplete_panel_left_open,
		autocomplete_start_overlay: toggle_instance_autocomplete_start_overlay_open,
		autocomplete_panel_detail_station: toggle_instance_autocomplete_panel_detail_station_open
	}
	
	const search_input_ref = useRef(null)
	
	let json_data = useContext(JSONDataContext)

	const set_is_open = (val) => {
		dispatch(instance_toggle_actions[instance_name](val))
	}

	const mobile_search_icon_clicked = () => {
		if(!is_open && type === 'header') {
			set_is_open(true)
		}
	}
	useEffect(() => {
		if(is_open) {
			// just opened myself -> clear_and_close all Instances except myself
			for(const i_name of AUTOCOMPLETE_INSTANCE_NAMES){
				if(i_name !== instance_name){
					dispatch(instance_toggle_actions[i_name](false))
				}
			}
		}

	    if(is_open && type === 'header') {
			search_input_ref.current.focus()
		}
    }, [is_open, dispatch, instance_toggle_actions, instance_name, type])

	// not only called by mouse event
	const input_clicked = () => {
		if(!is_open) {
			set_is_open(true)
		}
	}
	
	// not only called by mouse event
	const close_clicked = () => {
	    if(search_input_ref.current.value.length > 0){
	        search_input_ref.current.value = ''
			set_result_list([])
        }else if(is_open) {
			set_is_open(false)
		}
	}

	// use callback with is_open dependency
	const document_clicked = useCallback(event => {
		if (event.defaultPrevented) return; // do not react if the child prevents it
		if(is_open) {
			set_is_open(false)
		}
	}, [is_open, set_is_open])
	
	// add/remove event handlers with callback dependency
	useEffect(() => {
		document.addEventListener('click', document_clicked, false)
		return () => {
			document.removeEventListener('click', document_clicked);
		}
	}, [document_clicked]);
	
	// result in autocomplete clicked
	const result_clicked = (obj) => {
		// set the choosen value && close autocomplete
		if(search_input_ref !== null) {
			search_input_ref.current.value = obj.name
			close_clicked()
		}
		// change url
		let ut = ''
		let params = undefined
		if(type === 'header' || type === 'panel-left') {
			ut = obj.type === 'station' ? 'station_from': 'line'
			params = obj.type === 'station' ? { from_slug: obj.code } : { line_slug: obj.code }
		} else if(type === 'detail-panel') {
			ut = 'station_from_to'
			// do nothing if to_slug is the same like from_slug
			if(router_infos.from_slug !== obj.code) {
				params = { from_slug: router_infos.from_slug, to_slug: obj.code }
			}
		} 
		if(params !== undefined) {
			const url = resolve_url(ut, params)
			dispatch(toggle_panel_left_open(false)) // Todo: check if proper for every URL push
            dispatch(push(url))
            set_is_open(false)
		}
	}
	
	// we can safely assume that the context is given, when we access the input. Else the loader overlay would prevent keyup in the input
	const input_keyup = (event) => {
		var code = event.which;
		const value = event.target.value
		if(value.length >= 2) {
			input_clicked() // be sure it is open
			const autocomplete_data = json_data.general.autocomplete
			let result_list = []
			if(type === 'header' || type === 'panel-left') {
				result_list = autocomplete_highlight_list(autocomplete_search(autocomplete_data, value, undefined), value)
			} else if(type === 'detail-panel') { // search for stations only
				result_list = autocomplete_highlight_list(autocomplete_search(autocomplete_data, value, 'station'), value)
			}
			set_result_list(result_list)
		}
		// check if user hit enter, to  call direct the exact match (if given)
		if(code === 13) {
			// do we have a exact match
			let best_match_obj = autocomplete_get_best_match(result_list)
			if(best_match_obj !== null) {
				// console.log(best_match_obj)
				result_clicked(best_match_obj)
			}else if(search_input_ref.current.value !== ''){
                dispatch(toggle_panel_full_overlay_autocomplete_noresult_is_open(true)) // Todo: check if proper for every URL push
            }
		}
	}
	
	// prevent the form submit on enter
	const input_keypress = (event) => {
		var code = event.which;
		if(code === 13) {
			event.preventDefault()
		}
	}
	
	const result_keyup = (event, obj) => {
		var code = event.which;
		if(code === 13) {
			// simulate a click to get rid of the focus ring
			var evt = new MouseEvent('click', {
							bubbles: true,
							cancelable: true,
							view: window
						  });
			document.dispatchEvent(evt)
			result_clicked(obj)
		}
	}
	
	// assemble result list
	let ctr = 0
	let result_html_list = []
	for(const obj of result_list) {
		// the highlighted_name needs to be set with dangerouslySetInnerHTML, else it is text
		result_html_list.push(
			<li key={obj.code + '-' + ctr}
                className={ is_open? 'aria-select-focusable': '' }
                aria-selected="false"
				tabIndex="-1"
				role="option" 
				onClick={(event) => {
					event.preventDefault()
					result_clicked(obj)
				}}
				onKeyUp={(event) => {
					result_keyup(event, obj)
				}}
				dangerouslySetInnerHTML={{__html: obj.highlighted_name}} />
		)
		ctr += 1
	}
	
	// text and translations
	let input_label_text = ''
	let placeholder_text = ''
	let close_button_text = ''
	let ac_body_text = ''
	
	// class
    let button_search_icon = ''
	let main_class = "autocomplete"
	
	if(type === 'header') {
		input_label_text = t('Suchbegriff für Linie / Ausgangsbahnhof')
		placeholder_text = t('Suche nach Linie / Bahnhof')
		close_button_text = t('Autocomplete Vorschläge schliessen')
		ac_body_text = t('Linie oder Ausgangsbahnhof')
        main_class += " mobile-inactive-hidden"
        button_search_icon = (
            <button className="mobile-open-autocomplete mobile-only" onClick={mobile_search_icon_clicked}>
				<span className="visuallyhidden">{t('Linie / Bahnhof suchen')}</span>
				<SearchIcon />
			</button>
        )
	} else if(type === 'panel-left') {
		input_label_text = t('Suchbegriff für Linie / Ausgangsbahnhof')
		placeholder_text = t('Suche nach Linie / Bahnhof')
		close_button_text = t('Autocomplete Vorschläge schliessen')
		ac_body_text = t('Linie oder Ausgangsbahnhof')
        main_class += ""
	} else if(type === 'detail-panel') {
		input_label_text = t('Suchbegriff für Zielbahnhof')
		placeholder_text = t('Nach Zielbahnhof')
		close_button_text = t('Autocomplete Vorschläge schliessen')
		ac_body_text = t('Ziel im Fernverkehr auswählen')
        main_class += " ac-destination-selector"
	}
	
	// open, close
	if(is_open) {
		main_class += " autocomplete-open"
	}
	
	return (
	    <>
            {button_search_icon}
            <div className={main_class}>
                <div className="autocomplete-head"
                     role="combobox"
                     aria-expanded={is_open ? true: false}
                     aria-owns={`ac-${type}-listbox`}
                     aria-controls={`ac-${type}-listbox`}
                     aria-haspopup="listbox"
                     id={`ac-${type}-combobox`}>

                    <form>
                        <label className="visuallyhidden" id={`ac-${type}-label`} htmlFor={`ac-${type}-input`}>
                            {input_label_text}
                        </label>
                        <input 	name="search"
                                className="autocomplete-input"
                                id={`ac-${type}-input`}
                                inputMode="search"
                                type="text"

                                placeholder={placeholder_text}
                                aria-autocomplete="list"
                                aria-controls={`ac-${type}-listbox`}

                                data-init="autocomplete"
                                data-autocomplete-options=""

                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="none"
                                spellCheck="false"
                                ref={search_input_ref}
                                onClick={(event) => {
                                    event.preventDefault()
                                    input_clicked()
                                }}
                                onKeyUp={input_keyup}
                                onKeyPress={input_keypress} />
                    </form>
                    <button className="ac-close-handler close-autocomplete" onClick={(event) => {
                            event.preventDefault()
                            close_clicked()
                        }}>
                        <span className="visuallyhidden">{close_button_text}</span>
                            <div className="close-icon">
                                <CloseIcon />
                            </div>
                    </button>
                </div>

                <div className="autocomplete-body">
                    <header>{ac_body_text}</header>
                        <ul key={`ac-${type}-panel-result-list`}
                            aria-labelledby={`ac-${type}-label`}
                            role="listbox" id={`ac-${type}-label`}
                            className="listbox">
                        {result_html_list}
                        </ul>
                </div>
            </div>
        </>
	)
}

export default AutoComplete