import React, {useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import {ReactComponent as XClose} from "../../assets/svg/x_close.svg";
import {toggle_panel_full_overlay_autocomplete_noresult_is_open} from "../../actions";


const FullOverlayAutocompleteNoResult = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const is_overlay_open = useSelector(state => state.general.panels.panel_full_overlay_autocomplete_noresult_is_open);

    const full_overlay_close_button = useRef(null)

    const close_overlay_clicked = () => {
        dispatch(toggle_panel_full_overlay_autocomplete_noresult_is_open(false))
    }

    useEffect(() => {
	    if(is_overlay_open) {
			full_overlay_close_button.current.focus()
		}

    }, [is_overlay_open])

    return (
        <div className={`full-overlay  ${is_overlay_open === true ? 'full-overlay-open' : ""}`}>
            <header className="full-overlay-header">
                <div className="close-handler-wrapper">
                    <button className="close-handler" onClick={close_overlay_clicked} tabIndex="0" ref={full_overlay_close_button}>
                        <span className="visuallyhidden">{t("Overlay schliessen")}</span>
                        <div className="close-icon">
                            <div className="circle"></div>
                            <XClose />
                        </div>
                    </button>
                </div>
                <div className="full-overlay-title">
                    <h1>{t("Suchresultat")}</h1>
                </div>
            </header>
            <div className="full-overlay-body">
                <div className="title">
                    <h2>{t("Der eingegebene Suchbegriff lieferte kein Resultat.")}</h2>
                </div>
                <div className="text">
                    {t("Auf dieser Karte finden Sie nur Linien und Bahnhöfe des Fernverkehrs. Sobald Sie mehr als zwei Zeichen eingeben, werden Ihnen Vorschläge angezeigt. Wählen Sie anschliessend einen Vorschlag aus der Liste.")}
                </div>
                <button className="sbb-button-red" onClick={close_overlay_clicked}>
                    <div className="text">{t("Schliessen")}</div>
                </button>
            </div>
        </div>
    )

}

export default FullOverlayAutocompleteNoResult