import React, { useLayoutEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';

import {ReactComponent as SBBLogo} from '../../assets/svg/sbb_logo.svg'
import {ReactComponent as SBBLogoMobile} from '../../assets/svg/sbb_logo_mobile.svg'

import AutoComplete from './AutoComplete'

import {
	toggle_panel_left_open,
	set_panel_header_height
} from '../../actions'

const HeaderPanel = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();	
	const panel_left_open = useSelector(state => state.general.panels.panel_left_open);
	
	const header_ref = useRef(null)
	
	useLayoutEffect(() => {
		const { current } = header_ref
		if(current !== null) {
			dispatch(set_panel_header_height(current.offsetHeight))
		}
	}, [dispatch])
		
	const toggle_nav_clicked = (event) => {
		event.preventDefault();
		let new_panel_left_open = panel_left_open ? false : true;
		dispatch(toggle_panel_left_open(new_panel_left_open))
	}

	return (
		<header className="main-header" ref={header_ref}>
			<button className="toggle-nav" onClick={toggle_nav_clicked} tabIndex="1">
				<span className="visuallyhidden">{t('Hauptnavigation öffnen / schliessen')}</span>
				<div className="toggle-nav-icon">
					<div className="bar bar1"></div>
					<div className="bar bar2"></div>
					<div className="bar bar3"></div>
				</div>
			</button>

			<div className="header-autocomplete mobile-only">
				<AutoComplete type="header" instance_name="autocomplete_main_header" />
			</div>

            <div className="header-title">{t('Direktverbindungen Fernverkehr')}</div>

            <div className="header-autocomplete tablet-plus-only">
				<AutoComplete type="header" instance_name="autocomplete_main_header" />
			</div>

			<div className="sbb-logo logo-mobile mobile-only">
				<SBBLogoMobile />
			</div>
			<div className="sbb-logo logo-tablet-plus tablet-plus-only">
				<SBBLogo />
			</div>
		</header>
	)
}

export default HeaderPanel