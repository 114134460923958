import React from 'react';
import { useTranslation } from 'react-i18next';


const BasetactStop = (props) => {
    const { t } = useTranslation();

    const { basetact_stop } = props

    const class_infomessage_warning = basetact_stop.is_warning? ' infomessage-warning': ''
    const class_is_origin = basetact_stop.is_origin? ' is-origin': ''

    let time_arrival = ''
    if(basetact_stop.is_lineflow_start !== true){
        time_arrival = (
            <div className="arrival">
                <span className="visuallyhidden">{t("Ankunftsminute ")} </span>
                <span className="text">{basetact_stop.arrival_minute}</span>
            </div>
        )
    }
    let time_departure = ''
    if(basetact_stop.is_lineflow_end !== true) {
        time_departure = (
            <div className="departure">
                <span className="visuallyhidden">{t("Abfahrtsminute ")} </span>
                <span className={`text ${class_is_origin}`}>{basetact_stop.departure_minute}</span>
            </div>
        )
    }
    let class_visual = ' visual-default'
    let class_is_soft = ''
    if(basetact_stop.is_lineflow_start && basetact_stop.is_previous_stop){
        class_visual = ' visual-first-stop-inactive'
        time_arrival = ''
        time_departure = ''
        class_is_soft = ' soft'
    }else if(basetact_stop.is_lineflow_start || basetact_stop.is_origin){
        class_visual = ' visual-full-bullet'
    }else if(basetact_stop.is_lineflow_end){
        class_visual = ' visual-last-stop'
    }

    return (
        <li className={class_infomessage_warning}>
            <div className="time">
                <div className="time-group">
                    {time_arrival}
                    {time_departure}
                </div>
            </div>
            <div className={`visual ${class_visual}`} ></div>
            <div className="station-label">
                <span className={`text ${class_is_origin} ${class_is_soft}`}>{basetact_stop.station_label}</span>
            </div>
        </li>
    )

}

export default BasetactStop